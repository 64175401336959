import {wrapIntoMask} from '@/libls/phone'
import ModelInterface from '@/models/ModelInterface'
import {RawStaffUser} from '@/types/RawStaffUser'
import {DateTime} from 'luxon'

import store from '@/store'
import {SpecItemTypes} from '@/types/RawStaffDataSpec'
import {t} from '@/i18n'

export function getDataValueTextByTypeMap(type: SpecItemTypes, value: any) {
  const dataValueTextByTypeMap: {[k in SpecItemTypes]: (value: any) => any} = {
    string: (value) => value,
    integer: (value) => value,
    boolean: (value) => value ? t('models.content.StaffUser.booleanValueTrueText') : t('models.content.StaffUser.booleanValueFalseText'),
    list_of_strings: (value) => value?.join(', ') || '',
    email: (value) => value,
    phone: (value) => value,
    date: (value) => value,
    image: (value) => value
  }

  return dataValueTextByTypeMap[type](value)
}

export default class StaffUser extends ModelInterface<RawStaffUser> {

  public getCtText (): string {
    return DateTime.fromMillis(this.data._ct).toFormat('dd.MM.yyyy HH:mm')
  }

  public getTsText (): string {
    return DateTime.fromMillis(this.data._ts).toFormat('dd.MM.yyyy HH:mm')
  }

  public getDataValueText (key: string) {
    const spec = store.state.staffDataSpecification.dataSpecification
    const specItem = spec?.spec.find(item => item.key === key)

    if (!spec || !specItem) {
      return 'Specification error'
    }

    return getDataValueTextByTypeMap(specItem.type, this.data.data[key])
  }

  public interpolateString(template: string): string {
    return template.replace(/#\{(\w+)\}/g, (_, key: string) => {     
      return (this.staffData as any)[key] !== undefined ? String((this.staffData as any)[key]) : `#{${key}}`
    })
  }

  get staffData () {
    return this.data.data
  }

  get botUserId (): string {
    return this.data._botUserId || ''
  }

}