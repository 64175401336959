import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "ui-pagination" }
const _hoisted_2 = { class: "ui-pagination__total" }
const _hoisted_3 = {
  key: 0,
  class: "ui-pagination__cols",
  ref: "colsSelect"
}
const _hoisted_4 = { class: "ui-pagination__sizes" }
const _hoisted_5 = { class: "ui-pagination__pagination" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_el_config_provider = _resolveComponent("el-config-provider")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_config_provider, { locale: $setup.currentLocale }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('components.ui.UiPagination.totalLabel')) + ": " + _toDisplayString($props.total), 1 /* TEXT */),
        ($props.colsSelectList.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_el_select, {
                modelValue: $setup.test,
                multiple: "",
                "collapse-tags": "",
                onChange: $setup.onActiveColsChange,
                size: "small",
                style: {"width":"100%"}
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.colsSelectList, (item) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: item.value,
                      label: item.label,
                      value: item.value
                    }, null, 8 /* PROPS */, ["label", "value"]))
                  }), 128 /* KEYED_FRAGMENT */))
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["modelValue"])
            ], 512 /* NEED_PATCH */))
          : _createCommentVNode("v-if", true),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_el_select, {
            modelValue: $props.currentSize,
            onChange: $setup.onSizeChange,
            size: "small"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.sizes, (size) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  key: size,
                  label: size,
                  value: size
                }, null, 8 /* PROPS */, ["label", "value"]))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_el_pagination, {
            layout: "prev, pager, next",
            currentPage: $props.currentPage,
            "page-size": $props.currentSize,
            size: "small",
            total: $props.total,
            onCurrentChange: $setup.onPageChange
          }, null, 8 /* PROPS */, ["currentPage", "page-size", "total"])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["locale"])
  ]))
}