import ModelInterface from '@/models/ModelInterface'
import {RawInvite} from '@/types/RawInvite'
import {DateTime} from 'luxon'

export default class Invite extends ModelInterface<RawInvite> {

  public getCtText (): string {
    return DateTime.fromMillis(this.data._ct).toFormat('dd.MM.yyyy HH:mm')
  }

  public getTsText (): string {
    return DateTime.fromMillis(this.data._ts).toFormat('dd.MM.yyyy HH:mm')
  }

  get ctText () {
    return this.getCtText()
  }

  get email () {
    return this.data.email || ''
  }

  get status () {
    return this.data.status
  }
  
  get errorMessage () {
    return this.data.errorMessage
  }
}