<template>
  <div class="ui-page-title" :style="{marginBottom: margin + 'px'}">
    <div class="ui-page-title__text">
      <slot></slot>
    </div>
    <div class="ui-page-title__rightside">
      <slot name="rightside"></slot>
    </div>
  </div>
</template>
<script lang="ts" setup>
defineProps({
  margin: {
    type: Number,
    default: 25
  }
})
</script> 
<style lang="scss">
.ui-page-title {
  display: flex;
  gap: 20px;

  &__text {
    font-size: 42px;
    font-weight: 800;
    flex-grow: 1;
  }

  &__rightside {
    flex-shrink: 0;
    //
    display: flex;
    align-items: center;
  }
}
</style>