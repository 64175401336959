<template>
  <div :class="['ui-messenger-icon', 'ui-messenger-icon--' + messenger, {isDisabled: disabled}]" :style="{width: size + 'px', height: size + 'px'}">
    <img v-if="messenger === 'telegram'" src="../../assets/icons/tg_white.svg" alt="Telegram">
    <img v-if="messenger === 'viber'" src="../../assets/icons/vb_white.svg" alt="Viber">
  </div>
</template>
<script lang="ts" setup>
import {Dictionary} from '@/types/types';

const props = defineProps({
  messenger: {
    type: String,
    default: 'telegram'
  },
  size: {
    type: Number,
    default: 18
  },
  disabled: Boolean
})

</script>
<style lang="scss">
.ui-messenger-icon {
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 50%;
  }

  &--telegram {
    background: #279DD4;

    img {
      margin-left: -1px;
      margin-bottom: -1px;
    }
  }

  &--viber {
    background: #8527B4;

    img {
      // margin-bottom: -1px;
    }
  }

  &.isDisabled {
    background-color: #909399;
  }
}
</style>