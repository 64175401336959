import {createRouter, createWebHashHistory, RouteRecordRaw} from 'vue-router'
import auth from '@/services/auth'
import {store} from '@/store'
import {RawBot} from '@/types/RawBot'
import {
  ADMIN_PERMISSION_BOTS_CREATE,
  ADMIN_PERMISSION_BOTS_UPDATE,
  ADMIN_PERMISSION_CONTENT_NODES_CREATE,
  ADMIN_PERMISSION_CONTENT_NODES_GET,
  ADMIN_PERMISSION_CONTENT_NODES_UPDATE,
  ADMIN_PERMISSION_MAILINGS_CREATE,
  ADMIN_PERMISSION_MAILINGS_GET,
  ADMIN_PERMISSION_MAILINGS_UPDATE,
  ADMIN_PERMISSION_POLLS_CREATE,
  ADMIN_PERMISSION_POLLS_GET,
  ADMIN_PERMISSION_POLLS_UPDATE,
  ADMIN_PERMISSION_USER_ATTRS_GET,
  ADMIN_PERMISSION_USERS_GET,
  ADMIN_PERMISSION_USER_CONTEXT_GET,
  BOT_ACTIVE_LS_KEY,
  E_ACCESS,
  E_LOGIN,
  MODULE_MAILINGS,
  MODULE_NODES, MODULE_POLLS,
  MODULE_STAFF,
  MODULE_USERS
}
  from '@/constants'
import {ElMessage} from 'element-plus'
import Bot from '@/models/content/Bot'
import {ModuleType} from '@/types/RawModule'
import {checkModuleOptions} from '@/services/modules'


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
    children: [],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/modules',
    name: 'modules',
    component: () => import('../views/DefaultView.vue'),
    children: [
      {
        path: ':id/edit',
        name: 'moduleEdit',
        component: () => import('../pages/modules/ModuleEditPage.vue'),
        props: true
      }
    ]
  },
  {
    path: '/bots',
    name: 'bots',
    component: () => import('../views/DefaultView.vue'),
    children: [
      {
        path: 'create',
        name: 'botCreate',
        component: () => import('../pages/bots/CreateBotPage.vue'),
        meta: {
          access: [ADMIN_PERMISSION_BOTS_CREATE]
        }
      },
      {
        path: ':id/about',
        name: 'botAbout',
        component: () => import('../pages/bots/AboutBotPage.vue'),
        props: true
      },
      {
        path: ':id/edit',
        name: 'botEdit',
        component: () => import('../pages/bots/EditBotPage.vue'),
        meta: {
          access: [ADMIN_PERMISSION_BOTS_UPDATE]
        },
        props: true
      }
    ]
  },
  {
    path: '/content',
    name: 'content',
    redirect: '/content/tree',
    component: () => import('../views/DefaultView.vue'),
    beforeEnter: async (from, to, next) => {
      const ok = await checkModuleOptions(from.meta.module as ModuleType)
      if (ok) {
        next()
      } else {
        // @ts-ignore
        next({name: 'moduleEdit', params: {id: from.meta.module}, query: {from: from.fullPath, hideCancel: true, isMissingSettings: true}})
      }
    },
    meta: {
      module: MODULE_NODES
    },
    children: [
      {
        path: 'tree',
        name: 'contentTree',
        component: () => import('../pages/content/TreePage.vue'),
        meta: {
          access: [ADMIN_PERMISSION_CONTENT_NODES_GET]
        }
      },
      {
        path: 'create',
        name: 'contentCreate',
        component: () => import('../pages/content/CreatePage.vue'),
        meta: {
          access: [ADMIN_PERMISSION_CONTENT_NODES_CREATE]
        }
      },
      {
        path: ':id/about',
        name: 'contentNode',
        component: () => import('../pages/content/ContentNodePage.vue'),
        props: true,
        meta: {
          access: [ADMIN_PERMISSION_CONTENT_NODES_GET]
        }
      },
      {
        path: ':id/edit',
        name: 'contentEdit',
        component: () => import('../pages/content/EditPage.vue'),
        props: true,
        meta: {
          access: [ADMIN_PERMISSION_CONTENT_NODES_UPDATE]
        }
      },
    ]
  },
  {
    path: '/mailings',
    name: 'mailings',
    redirect: '/mailings/list',
    component: () => import('../views/DefaultView.vue'),
    beforeEnter: async (from, to, next) => {
      const ok = await checkModuleOptions(from.meta.module as ModuleType)
      if (ok) {
        next()
      } else {
        // @ts-ignore
        next({name: 'moduleEdit', params: {id: from.meta.module}, query: {from: from.fullPath, hideCancel: true, isMissingSettings: true}})
      }
    },
    meta: {
      module: MODULE_MAILINGS
    },
    children: [
      {
        path: 'create',
        name: 'mailingCreate',
        component: () => import('../pages/mailings/CreateMailingPage.vue'),
        meta: {
          access: [ADMIN_PERMISSION_MAILINGS_CREATE]
        }
      },
      {
        path: ':id/about',
        name: 'mailingAbout',
        component: () => import('../pages/mailings/AboutMailingPage.vue'),
        props: true,
        meta: {
          access: [ADMIN_PERMISSION_MAILINGS_GET]
        }
      },
      {
        path: ':id/edit',
        name: 'mailingEdit',
        component: () => import('../pages/mailings/EditMailingPage.vue'),
        props: true,
        meta: {
          access: [ADMIN_PERMISSION_MAILINGS_UPDATE]
        }
      },
      {
        path: 'list',
        name: 'mailingList',
        component: () => import('../pages/mailings/ListMailingPage.vue'),
        meta: {
          access: [ADMIN_PERMISSION_MAILINGS_GET]
        }
      },
    ]
  },
  {
    path: '/polls',
    name: 'polls',
    redirect: '/polls/list',
    component: () => import('../views/DefaultView.vue'),
    beforeEnter: async (from, to, next) => {
      const ok = await checkModuleOptions(from.meta.module as ModuleType)
      if (ok) {
        next()
      } else {
        // @ts-ignore
        next({name: 'moduleEdit', params: {id: from.meta.module}, query: {from: from.fullPath, hideCancel: true, isMissingSettings: true}})
      }
    },
    meta: {
      module: MODULE_POLLS
    },
    children: [
      {
        path: 'create',
        name: 'pollCreate',
        component: () => import('../pages/polls/CreatePollPage.vue'),
        meta: {
          access: [ADMIN_PERMISSION_POLLS_CREATE]
        }
      },
      {
        path: ':id/about',
        name: 'pollAbout',
        component: () => import('../pages/polls/AboutPollPage.vue'),
        props: true,
        meta: {
          access: [ADMIN_PERMISSION_POLLS_GET]
        }
      },
      {
        path: ':id/edit',
        name: 'pollEdit',
        component: () => import('../pages/polls/EditPollPage.vue'),
        props: true,
        meta: {
          access: [ADMIN_PERMISSION_POLLS_UPDATE]
        }
      },
      {
        path: 'list',
        name: 'pollList',
        component: () => import('../pages/polls/ListPollPage.vue'),
        meta: {
          access: [ADMIN_PERMISSION_POLLS_GET]
        }
      }
    ]
  },
  {
    path: '/users',
    name: 'users',
    redirect: '/users/list',
    component: () => import('../views/DefaultView.vue'),
    beforeEnter: async (from, to, next) => {
      const ok = await checkModuleOptions(from.meta.module as ModuleType)
      if (ok) {
        next()
      } else {
        // @ts-ignore
        next({name: 'moduleEdit', params: {id: from.meta.module}, query: {from: from.fullPath, hideCancel: true, isMissingSettings: true}})
      }
    },
    meta: {
      module: MODULE_USERS
    },
    children: [
      {
        path: 'list',
        name: 'usersList',
        component: () => import('../pages/users/ListUsersPage.vue'),
        meta: {
          access: [ADMIN_PERMISSION_USERS_GET]
        }
      },
      {
        path: 'attrs',
        name: 'userAttrsList',
        component: () => import('../pages/users/UserAttrsListPage.vue'),
        meta: {
          access: [ADMIN_PERMISSION_USER_ATTRS_GET]
        }
      },
      {
        path: 'context',
        name: 'userContextList',
        component: () => import('../pages/users/UserContextListPage.vue'),
        meta: {
          access: [ADMIN_PERMISSION_USER_CONTEXT_GET]
        }
      },
      {
        path: ':id/about',
        name: 'userAbout',
        component: () => import('../pages/users/AboutUserPage.vue'),
        props: true,
        meta: {
          access: [ADMIN_PERMISSION_USERS_GET]
        }
      },
    ]
  },
  {
    path: '/staff',
    name: 'staff',
    redirect: '/staff/list',
    component: () => import('../views/DefaultView.vue'),
    meta: {
      module: MODULE_STAFF
    },
    children: [
      {
        path: 'list',
        name: 'staffList',
        component: () => import('../pages/staff/StaffListPage.vue'),
        beforeEnter: async (from, to, next) => {
          const ok = await checkModuleOptions(from.meta.module as ModuleType)
          if (ok) {
            next()
          } else {
            // @ts-ignore
            next({name: 'moduleEdit', params: {id: from.meta.module}, query: {from: from.fullPath, hideCancel: true, isMissingSettings: true}})
          }
        },
        meta: {
          access: [ADMIN_PERMISSION_USERS_GET],
          module: MODULE_STAFF
        }
      },
      {
        path: 'invites',
        name: 'invitesList',
        component: () => import('../pages/staff/InvitesListPage.vue'),
        beforeEnter: async (from, to, next) => {
          const ok = await checkModuleOptions(from.meta.module as ModuleType)
          if (ok) {
            next()
          } else {
            // @ts-ignore
            next({name: 'moduleEdit', params: {id: from.meta.module}, query: {from: from.fullPath, hideCancel: true, isMissingSettings: true}})
          }
        },
        meta: {
          access: [ADMIN_PERMISSION_USERS_GET],
          module: MODULE_STAFF
        }
      },
      {
        path: 'settings',
        name: 'staffSettings',
        component: () => import('../pages/staff/StaffSettingsPage.vue'),
        meta: {
          access: [ADMIN_PERMISSION_USER_ATTRS_GET]
        }
      }
    ]
  },
  {
    path: '/services',
    name: 'services',
    component: () => import('../views/DefaultView.vue'),
    children: [
      {
        path: ':id/about',
        name: 'serviceAbout',
        component: () => import('../pages/services/AboutServicePage.vue'),
        props: true
      },
      {
        path: 'shop',
        name: 'serviceShop',
        component: () => import('../pages/services/ShopServicePage.vue'),
        props: true
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: () => import('../views/NotFoundView.vue'),
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach(async (to, from) => {
  if (!store.getters.isAuthenticated && to.name !== 'login' && auth.readToken()) {
    try {
      const credentials = auth.readToken()
      if (credentials) {
        await auth.login(credentials)
      }
    } catch (error: any) {
      if (error.code === E_ACCESS || error.code === E_LOGIN) {
        auth.logout()
      } else {
        ElMessage.error('Щось пішло не так')
        throw error
      }
    }
  }

  // ПЕРЕВІРИТИ що є боти, та є активні месенжери
  const hasActiveBots = store.getters['bots/hasActiveBots']
  const activeBot = store.getters['bots/getActiveBot'] as Bot
  const hasActiveMessengers = activeBot?.hasActiveMessengers()

  if (!auth.isAuthenticated() && to.name !== 'login') {
    console.log(1);
    return {name: 'login'}
  } else if (auth.isAuthenticated() && (!hasActiveBots || !hasActiveMessengers) && (to.name !== 'home' && to.name !== 'botCreate' && to.name !== 'botAbout' && to.name !== 'botEdit')) {
    console.log(2);
    return {name: 'home'}
  } else if (auth.isAuthenticated() && to.name === 'login') {
    console.log(3);
    return {name: 'home'}
  }

  // TODO перевірка що у активного бота є цей модуль
  const module = to.meta.module as string

  if (module && activeBot) {
    const hasModule = activeBot.modules.find(md => md.module === module)
    if (!hasModule) {
      return {name: 'notFound'}
    }
  }

  // перевірка пермішена
  const admin = store.state.admin
  const access = to.meta.access as string[] | undefined

  if (access?.length) {
    if (!admin || !admin.permissions.length || !admin.checkPermissions(access)) {
      return {name: 'notFound'}
    }
  }

})

export default router
