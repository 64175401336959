<template>
  <div class="ui-segmented">
    <el-segmented :modelValue="modelValue" :options="options"  @change="onChange"/>
  </div>
</template>
<script lang="ts" setup>

const props = defineProps({
  modelValue: {
    type: String,
    default: ''
  },
  options: {
    type: Array as () => string[] | {label: string, value: string}[],
    default: () => [] 
  }
})

const emit = defineEmits(['update:modelValue', 'change'])

function onChange (value: any) {
  emit('update:modelValue', value)
  emit('change', value)
}

</script>
<style lang="scss">
.ui-segmented {
  .el-segmented {
      --el-border-radius-base: 8px;

      &__group {
        column-gap: 15px;
      }
    }
}

</style>