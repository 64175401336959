import {Dictionary} from '@/types/types';
import {Module} from 'vuex'
import {RootState} from '../index';
import apiRequest from '@/services/apiRequest';
import {API_METHODS} from '@/constants';
import {RawPoll} from '@/types/RawPoll';
import PollResponse from '@/models/content/PollResponse';
import {RawPollResponse} from '@/types/RawPollResponse';

export interface PollResponseStore {
  pollResponses: Dictionary<PollResponse>
}

const polls = {
  namespaced: true,
  state: () => ({
    pollResponses: {},
  }),
  mutations: {
    setPollResponse (state, data: RawPollResponse) {
      const mailing = state.pollResponses[data._id]
      if (mailing) {
        mailing.setData(data)
      } else {
        state.pollResponses[data._id] = new PollResponse(data)
      }
    }
  },
  getters: {},
  actions: {
    async getList (context, {query = {}, size = 10000, from = 0, search}: {query: any, size: number, from: number, search: string}) {
      const {documents: polls, total} = await apiRequest({method: API_METHODS.pollResponse.get.list, data: {query, search, size, from, sort: {'_ct': -1}}})

      const list: PollResponse[] = []

      for (const poll of polls as RawPollResponse[]) {
        context.commit('setPollResponse', poll)
        list.push(context.state.pollResponses[poll._id])
      }

      return {documents: list, total}
    }
  }
} as Module<PollResponseStore, RootState>;

export default polls;