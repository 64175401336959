import {NODE_POST_TYPE_ATTACHMENTS, NODE_POST_TYPE_CONTEXT, NODE_POST_TYPE_IMAGE, NODE_POST_TYPE_IMAGES, NODE_POST_TYPE_TEXT, NODE_POST_TYPE_VIDEO, NODE_STATUS_ARCHIVE, NODE_STATUS_ARCHIVE_WEIGHT, NODE_STATUS_DRAFT, NODE_STATUS_DRAFT_WEIGHT, NODE_STATUS_IN_TEST, NODE_STATUS_IN_TEST_WEIGHT, NODE_STATUS_PUBLISHED, NODE_STATUS_PUBLISHED_WEIGHT, NODE_TYPE_CONTENT, NODE_TYPE_SERVICE} from '@/constants'
import ModelInterface from '@/models/ModelInterface'
import {NodeAttachment, NodeAttachmentsValue, NodeContextType, NodeGroupItem, NodeImageType, NodeImages, NodeImagesValue, NodeText, NodeTextValue, NodeVideoType, RawNode} from '@/types/RawNode'
import {Dictionary} from '@/types/types'

import {t} from '@/i18n'

export function getPostTypeData (type: string) {
  const PostTypes: Dictionary<{label: string, empty: NodeText | NodeImages | NodeAttachment | NodeImageType | NodeVideoType | NodeContextType}> = {
    [NODE_POST_TYPE_TEXT]: {label: t('models.content.ContentNode.textPostLabel'), empty: {type: NODE_POST_TYPE_TEXT, value: {default: ''}} as NodeText},
    [NODE_POST_TYPE_IMAGES]: {label: t('models.content.ContentNode.imagesTypeLabel'), empty: {type: NODE_POST_TYPE_IMAGES, value: []} as NodeImages},
    [NODE_POST_TYPE_IMAGE]: {
      label: t('models.content.ContentNode.imageTypeLabel'), empty: {
        type: NODE_POST_TYPE_IMAGE,
        value: {
          url: '',
          text: {
            default: '',
            viber: '',
            telegram: ''
          }
        }
      } as NodeImageType
    },
    [NODE_POST_TYPE_VIDEO]: {
      label: t('models.content.ContentNode.videoTypeLabel'),
      empty: {
        type: NODE_POST_TYPE_VIDEO,
        value: {
          url: '',
          text: {
            default: '',
            viber: '',
            telegram: ''
          },
          metta: {
            type: '',
            name: '',
            size: 0
          }
        }
      } as NodeVideoType
    },
    [NODE_POST_TYPE_ATTACHMENTS]: {label: t('models.content.ContentNode.attachTypeLabel'), empty: {type: NODE_POST_TYPE_ATTACHMENTS, value: []} as NodeAttachment},
    [NODE_POST_TYPE_CONTEXT]: {label: t('models.content.ContentNode.contextTypeLabel'), empty: {type: NODE_POST_TYPE_CONTEXT, value: {text: '', contextId: ''}} as NodeContextType}
  }

  return PostTypes[type]
}

export function NODE_STATUSES_MAP () {
  return {
    [NODE_STATUS_ARCHIVE]: {
      weight: NODE_STATUS_ARCHIVE_WEIGHT,
      name: t('constants.NODE_STATUS_ARCHIVE_NAME')
    },
    [NODE_STATUS_DRAFT]: {
      weight: NODE_STATUS_DRAFT_WEIGHT,
      name: t('constants.NODE_STATUS_DRAFT_NAME')
    },
    [NODE_STATUS_IN_TEST]: {
      weight: NODE_STATUS_IN_TEST_WEIGHT,
      name: t('constants.NODE_STATUS_IN_TEST_NAME')
    },
    [NODE_STATUS_PUBLISHED]: {
      weight: NODE_STATUS_PUBLISHED_WEIGHT,
      name: t('constants.NODE_STATUS_PUBLISHED_NAME')
    }
  }
};

export function NODE_TYPES_MAP (): Dictionary<string> {
  return {
    [NODE_TYPE_CONTENT]: t('constants.NODE_TYPE_CONTENT_NAME'),
    [NODE_TYPE_SERVICE]: t('constants.NODE_TYPE_SERVICE_NAME')
  }
}


export const groupEmptyItem: NodeGroupItem = {
  text: {
    default: '',
    viber: '',
    telegram: ''
  },
  image: ''
}

export default class ContentNode extends ModelInterface<RawNode> {
  public getTitle () {
    return this.data.title
  }

  public getTitleText () {
    return this.getTitle().text
  }

  public getParentId () {
    return this.data.parentId
  }

  public getPost () {
    return this.data.post || []
  }


  public getGroup () {
    return this.data.group || []
  }

  static getPostTyleLabel (type: string): string {
    return getPostTypeData(type).label || 'error'
  }

  static getPostEmpty (type: string): NodeText | NodeImages | NodeAttachment | NodeImageType | NodeVideoType | NodeContextType {
    return getPostTypeData(type).empty
  }
}