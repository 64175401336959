import ModelInterface from '@/models/ModelInterface'
import {RawPollResponse} from '@/types/RawPollResponse'
import User from './User'

export default class PollResponse extends ModelInterface<RawPollResponse> {
  get status () {
    return this.data.status || ''
  }

  get pollId () {
    return this.data.pollId
  }

  get userMsgrs () {
    return this.data.user?.msgrs
  }

  get response () {
    return this.data.response || []
  }

  get _user () {
    return this.data._user ? new User(this.data._user) : null
  }
}