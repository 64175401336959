import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "ui-popup__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Close = _resolveComponent("Close")!
  const _component_el_icon = _resolveComponent("el-icon")!

  return ($setup.isVisible)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(['ui-popup', {hasCloseIcon: $props.showCloseIcon}]),
        onClick: $setup.closeModal
      }, [
        _createElementVNode("div", {
          class: "ui-popup__modal",
          onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
        }, [
          ($props.showCloseIcon)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "ui-popup__close-icon",
                onClick: $setup.closeModal
              }, [
                _createVNode(_component_el_icon, { size: 20 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Close)
                  ]),
                  _: 1 /* STABLE */
                })
              ]))
            : _createCommentVNode("v-if", true),
          _createVNode($setup["PerfectScrollbar"], { options: {suppressScrollX: true, swipeEasing: true, scrollYMarginOffset: 5} }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _renderSlot(_ctx.$slots, "default")
              ])
            ]),
            _: 3 /* FORWARDED */
          })
        ])
      ], 2 /* CLASS */))
    : _createCommentVNode("v-if", true)
}