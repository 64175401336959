<template>
  <div :class="['annotation-block', 'annotation-block--' + type]">
    <div class="annotation-block__icon" v-if="showIcon">
      <svg v-if="type === 'warning'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path fill="currentColor" d="M512 64a448 448 0 1 1 0 896 448 448 0 0 1 0-896zm0 192a58.432 58.432 0 0 0-58.24 63.744l23.36 256.384a35.072 35.072 0 0 0 69.76 0l23.296-256.384A58.432 58.432 0 0 0 512 256zm0 512a51.2 51.2 0 1 0 0-102.4 51.2 51.2 0 0 0 0 102.4z"></path></svg>
    </div>
    <div class="annotation-block__content">
      <div class="annotation-block__label" v-if="label">{{ label }}</div>
      <slot></slot>
    </div>
  </div>
</template>
<script lang="ts" setup>
defineProps({
  type: {
    type: String,
    default: 'info'
  },
  showIcon: Boolean,
  label: String
})
</script>
<style lang="scss">
.annotation-block {
  display: flex;
  column-gap: 15px;
  padding: 20px;
  border-radius: 5px;
  line-height: 1.5;
  border: 1px solid;
  align-items: center;

  &__icon {
    width: 25px;
  }

  &__label {
    font-size: 18px;
    font-weight: 700;
  }

  &--info {
    color: #909399;
    background: #f4f4f5;
  }

  &--warning {
    background: #fdf6ec;
    color: #e6a23c;
  }

  &--success {
    color: #67c23a;
    background: #f0f9eb;
  }

  &--danger {
    color: #f56c6c;
    background: #fef0f0;
  }
}
</style>