import { createI18n } from 'vue-i18n'

import en from './locales/en.json'
import uk from './locales/uk.json'
import {LANGUAGES_TYPE} from '@/constants';

// @ts-ignore
let i18n;

export default function init (lang: string) {
  console.log('INIT i18n');

  const locales = {en, uk}
  i18n = createI18n({
    legacy: false,
    locale: lang,
    fallbackLocale: lang,
    messages: Object.assign(locales)
  })
  return i18n
}

export function t (path: string, data = {}) {
  // @ts-ignore
  return i18n.global?.t(path, data) || ''
}

export function getLocale (): LANGUAGES_TYPE {
  // @ts-ignore
  return i18n.global?.locale?.value
}



