import {Dictionary} from '@/types/types';
import {Module} from 'vuex'
import {RootState} from '../index';
import apiRequest from '@/services/apiRequest';
import {API_METHODS} from '@/constants';
import {RawBot} from '@/types/RawBot';
import Bot from '@/models/content/Bot';

export interface BotsStore {
  bots: Dictionary<Bot>
  activeBotId: string
}

const content = {
  namespaced: true,
  state: () => ({
    bots: {},
    activeBotId: ''
  }),
  mutations: {
    setBot(state, data: RawBot) {
      const bot = state.bots[data._id]
      
      if (bot) {
        bot.setData(data)
      } else {
        state.bots[data._id] = new Bot(data)
      }
    },
    setActiveBot (state, id: string) {
      state.activeBotId = id
    }
  },
  getters: {
    getActiveBot: (state) => {
      return state.bots[state.activeBotId]
    },
    hasBots: (state): boolean => {
      return !!Object.keys(state.bots).length
    },
    hasActiveBots: (state): boolean => {
      for (const key in state.bots) {
        const bot = state.bots[key];

        if (bot.hasActiveMessengers()) {
          return true
        }
      }

      return false
    }
  },
  actions: {
    async getBot (context, id: string) {
      const {bot} = await apiRequest({method: API_METHODS.bots.get.bot, data: {id}})
      if (!bot) {
        return null
      }
      context.commit('setBot', bot)
      return context.state.bots[bot._id]
    },
    async getList (context): Promise<RawBot[]> {
      const {bots} = await apiRequest({method: API_METHODS.bots.get.list})
      if (!bots) {
        return []
      }
      for (const bot of bots) {
        context.commit('setBot', bot)
      }
      return bots
    },
    async createBot (_, data) {
      return await apiRequest({method: API_METHODS.bots.create.bot, data: {bot: data}})
    },
    async updateBot (_, {id, data}) {
      return await apiRequest({method: API_METHODS.bots.update.bot, data: {id, fields: data}})
    },
    async bindMessenger (_, data) {
      return await apiRequest({method: API_METHODS.bots.update.bindMessenger, data})
    },
    async unBindMessenger (_, data) {
      return await apiRequest({method: API_METHODS.bots.update.unBindMessenger, data})
    },
    async updateService (_, data) {
      return await apiRequest({method: API_METHODS.bots.update.updateService, data})
    },
    async updateModuleOptions (_, data) {
      return await apiRequest({method: API_METHODS.bots.update.updateModuleOptions, data})
    }
  }
} as Module<BotsStore, RootState>;

export default content;