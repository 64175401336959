<template>
  <div class="ui-markdown md" v-html="html">
  </div>
</template>
<script lang="ts" setup>
import { marked } from 'marked';
import {computed} from 'vue';

const props = defineProps ({
  text: {
    type: String,
    default: ''
  }
})

const html = computed(() => {
  return marked.parse(props.text);
})
</script>

<style lang="scss">
.md {
    color: #303030;
    word-wrap: break-word
}

.md [dir='auto'] {
    text-align: initial
}

.md *:first-child {
    margin-top: 0
}

.md>:last-child {
    margin-bottom: 0
}

.md p {
    color: #303030;
    margin: 0 0 16px
}

.md p>code {
    font-weight: inherit
}

.md p a:not(.no-attachment-icon) img {
    margin-bottom: 0
}

.md a {
    color: #1068bf
}

.md a>code {
    color: #1068bf
}

.md .media-container {
    display: inline-flex;
    flex-direction: column;
    margin-bottom: 0.25rem
}

.md img {
    max-width: 100%
}

.md img:not(.emoji) {
    margin: 0 0 8px
}

.md img.lazy {
    min-width: 200px;
    min-height: 100px;
    background-color: #fdfdfd
}

.md img.js-lazy-loaded,.md img.emoji {
    min-width: inherit;
    min-height: inherit;
    background-color: inherit
}

.md details {
    margin-bottom: 16px
}

.md details>*:not(summary) {
    margin-left: 1rem
}

.md summary>* {
    display: inline-block;
    margin-bottom: 0
}

.md code {
    font-family: "Menlo", "DejaVu Sans Mono", "Liberation Mono", "Consolas", "Ubuntu Mono", "Courier New", "andale mono", "lucida console", monospace;
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: keep-all
}

.md h1 {
    font-size: 1.75em;
    font-weight: 600;
    margin: 24px 0 16px;
    padding-bottom: 0.3em;
    border-bottom: 1px solid #eaeaea;
    color: #303030
}

.md h1:first-child {
    margin-top: 0
}

.md h2 {
    font-size: 1.5em;
    font-weight: 600;
    margin: 24px 0 16px;
    padding-bottom: 0.3em;
    border-bottom: 1px solid #eaeaea;
    color: #303030
}

.md h3 {
    margin: 24px 0 16px;
    font-size: 1.3em
}

.md h4 {
    margin: 24px 0 16px;
    font-size: 1.2em
}

.md h5 {
    margin: 24px 0 16px;
    font-size: 1em
}

.md h6 {
    margin: 24px 0 16px;
    font-size: 0.95em
}

.md blockquote,.md .blockquote {
    font-size: inherit;
    color: #525252;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1.5rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    margin-left: 0;
    margin-right: 0;
    box-shadow: inset 4px 0 0 0 #dbdbdb
}

.md blockquote:dir(rtl),.md .blockquote:dir(rtl) {
    border-left: 0;
    border-right: 3px solid #eaeaea
}

.md blockquote p,.md .blockquote p {
    line-height: 1.5;
    color: inherit
}

.md blockquote p:last-child,.md .blockquote p:last-child {
    margin: 0
}

.md hr {
    border-color: rgba(0,0,0,0.15);
    margin: 10px 0
}

.md table:not(.code) {
    margin: 16px 0;
    color: #303030;
    border: 0;
    width: auto;
    display: block;
    overflow-x: auto
}

.md table:not(.code) tbody {
    background-color: #fff
}

.md table:not(.code) td,.md table:not(.code) th {
    border: 1px solid #dbdbdb
}

.md table:not(.code) tr th {
    border-bottom: solid 2px #bfbfbf
}

.md table.grid-none:not(.code)>thead>tr>th {
    border-bottom-width: 0;
    border-right-width: 0;
    border-left-width: 0
}

.md table.grid-none:not(.code)>thead>tr>th:first-child {
    border-left-width: 1px
}

.md table.grid-none:not(.code)>thead>tr>th:last-child {
    border-right-width: 1px
}

.md table.grid-none:not(.code)>tbody>tr>td {
    border-width: 0
}

.md table.grid-none:not(.code)>tbody>tr>td:first-child {
    border-left-width: 1px
}

.md table.grid-none:not(.code)>tbody>tr>td:last-child {
    border-right-width: 1px
}

.md table.grid-none:not(.code)>tbody>tr:last-child>td {
    border-bottom-width: 1px
}

.md table.grid-rows:not(.code)>thead>tr>th,.md table.grid-rows:not(.code)>tbody>tr>td {
    border-right-width: 0;
    border-left-width: 0
}

.md table.grid-rows:not(.code)>thead>tr>th:first-child {
    border-left-width: 1px
}

.md table.grid-rows:not(.code)>thead>tr>th:last-child {
    border-right-width: 1px
}

.md table.grid-rows:not(.code)>tbody>tr>td {
    border-left-width: 0;
    border-right-width: 0
}

.md table.grid-rows:not(.code)>tbody>tr>td:first-child {
    border-left-width: 1px
}

.md table.grid-rows:not(.code)>tbody>tr>td:last-child {
    border-right-width: 1px
}

.md table.grid-cols:not(.code)>thead>tr>th {
    border-bottom-width: 0
}

.md table.grid-cols:not(.code)>tbody>tr>td {
    border-top-width: 0;
    border-bottom-width: 0
}

.md table.grid-cols:not(.code)>tbody>tr:last-child>td {
    border-bottom-width: 1px
}

.md table.frame-sides:not(.code)>thead>tr>th {
    border-top-width: 0
}

.md table.frame-sides:not(.code)>tbody>tr:last-child>td {
    border-bottom-width: 0
}

.md table.frame-topbot:not(.code)>thead>tr>th:first-child,.md table.frame-topbot:not(.code)>tbody>tr>td:first-child,.md table.frame-ends:not(.code)>thead>tr>th:first-child,.md table.frame-ends:not(.code)>tbody>tr>td:first-child {
    border-left-width: 0
}

.md table.frame-topbot:not(.code)>thead>tr>th:last-child,.md table.frame-topbot:not(.code)>tbody>tr>td:last-child,.md table.frame-ends:not(.code)>thead>tr>th:last-child,.md table.frame-ends:not(.code)>tbody>tr>td:last-child {
    border-right-width: 0
}

.md table.frame-none:not(.code)>thead>tr>th {
    border-top-width: 0
}

.md table.frame-none:not(.code)>tbody>tr:last-child>td {
    border-bottom-width: 0
}

.md table.frame-none:not(.code)>thead>tr>th:first-child,.md table.frame-none:not(.code)>tbody>tr>td:first-child {
    border-left-width: 0
}

.md table.frame-none:not(.code)>thead>tr>th:last-child,.md table.frame-none:not(.code)>tbody>tr>td:last-child {
    border-right-width: 0
}

.md table.stripes-all:not(.code) tr,.md table.stripes-odd:not(.code) tr:nth-of-type(odd),.md table.stripes-even:not(.code) tr:nth-of-type(even),.md table.stripes-hover:not(.code) tr:hover {
    background: #fafafa
}

.md table:dir(rtl) th {
    text-align: right
}

.md pre {
    margin-bottom: 16px;
    font-size: 13px;
    line-height: 1.6em;
    overflow-x: auto;
    border-radius: 2px
}

.md pre code {
    white-space: pre;
    word-wrap: normal;
    overflow-wrap: normal
}

.md pre.plain-readme {
    background: none;
    border: 0;
    padding: 0;
    margin: 0;
    font-size: 14px
}

.md dd {
    margin-left: 16px
}

.md ul,.md ol {
    padding: 0;
    margin: 0 0 16px
}

.md ul ul,.md ul ol,.md ol ul,.md ol ol {
    margin-bottom: 0
}

.md ul p~ol,.md ul p~ul,.md ol p~ol,.md ol p~ul {
    margin-bottom: 16px
}

.md ul:dir(rtl),.md ol:dir(rtl) {
    margin: 3px 28px 3px 0 !important
}

.md>ul {
    list-style-type: disc
}

.md>ul ul {
    list-style-type: circle
}

.md>ul ul ul {
    list-style-type: square
}

.md ul.checklist,.md ul.none,.md ol.none,.md ul.no-bullet,.md ol.no-bullet,.md ol.unnumbered,.md ul.unstyled,.md ol.unstyled {
    list-style-type: none
}

.md ul.checklist li,.md ul.none li,.md ol.none li,.md ul.no-bullet li,.md ol.no-bullet li,.md ol.unnumbered li,.md ul.unstyled li,.md ol.unstyled li {
    margin-left: 0
}

.md li {
    line-height: 1.6em;
    margin-left: 25px;
    padding-left: 3px
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    .md li {
        margin-left:28px;
        padding-left: 0
    }
}

.md ul.task-list>li.task-list-item {
    list-style-type: none;
    position: relative;
    min-height: 22px;
    padding-inline-start:28px;margin-inline-start:0 !important}

.md ul.task-list>li.task-list-item>input.task-list-item-checkbox {
    position: absolute;
    inset-inline-start: 8px;
    top: 5px
}

.md a.with-attachment-icon::before,.md a[href*='/uploads/']::before,.md a[href*='storage.googleapis.com/google-code-attachments/']::before {
    margin-right: 4px;
    font-style: normal;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    content: '📎'
}

.md a.no-attachment-icon[href*='/uploads/']::before,.md a.no-attachment-icon[href*='storage.googleapis.com/google-code-attachments/']::before {
    display: none
}

.md h1 a.anchor,.md h2 a.anchor,.md h3 a.anchor,.md h4 a.anchor,.md h5 a.anchor,.md h6 a.anchor {
    float: left;
    margin-left: -20px;
    text-decoration: none;
    outline: none
}

.md h1:hover>a.anchor::after,.md h2:hover>a.anchor::after,.md h3:hover>a.anchor::after,.md h4:hover>a.anchor::after,.md h5:hover>a.anchor::after,.md h6:hover>a.anchor::after {
    visibility: visible
}

.md h1>a.anchor:focus::after,.md h2>a.anchor:focus::after,.md h3>a.anchor:focus::after,.md h4>a.anchor:focus::after,.md h5>a.anchor:focus::after,.md h6>a.anchor:focus::after {
    visibility: visible;
    outline: auto
}

.md .big {
    font-size: larger
}

.md figcaption,.md .small {
    font-size: smaller
}

.md .underline {
    text-decoration: underline
}

.md .overline {
    text-decoration: overline
}

.md .line-through {
    text-decoration: line-through
}

.md .fa {
    display: inline-block;
    font-style: normal;
    font-size: 14px;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.md .admonitionblock td.icon [class^='fa icon-'] {
    font-size: 2em
}

.md .admonitionblock td.icon .icon-warning::before {
    content: '⚠'
}

.md .admonitionblock td.icon .icon-important::before {
    content: '❗'
}

.md .admonitionblock td.icon .icon-tip::before {
    content: '💡'
}

.md .admonitionblock td.icon .icon-note::before {
    content: '📌'
}

.md .admonitionblock td.icon .icon-caution::before {
    content: '🔥'
}

.md .fa-square-o::before {
    content: '\2610'
}

.md .fa-check-square-o::before {
    content: '\2611'
}

.md .admonitionblock td.icon {
    width: 1%
}

.md .metrics-embed h3.popover-header {
    margin: 0;
    font-size: 12px
}

.md .gl-new-dropdown-item {
    margin: 0;
    padding: 0;
    line-height: 1rem
}

.md .text-left {
    text-align: left !important
}

.md .text-right {
    text-align: right !important
}

.md .text-center {
    text-align: center !important
}

.md .text-justify {
    text-align: justify !important
}

.md .code.language-email {
    font-family: inherit;
    font-size: inherit
}

.md .code.language-email code {
    white-space: pre-wrap;
    font-family: inherit
}

.md .code.language-email code .nt,.md .code.language-email code .o {
    color: inherit;
    font-weight: bold
}

.md .code.language-email code .na {
    color: inherit
}

.md .code.language-email code .c {
    color: #7f8fa4;
    font-style: italic
}
</style>