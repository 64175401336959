<template>
  <div class="ui-help-tooltip">
    <el-popover :placement="placment" :width="200" trigger="hover" popper-class="ui-help-tooltip__popper">
      <template #reference>
        <div class="ui-help-tooltip__button">
          ?
        </div>
      </template>
      <slot></slot>
    </el-popover>
  </div>
</template>
<script lang="ts" setup>
defineProps({
  margin: {
    type: Number,
    default: 25
  },
  placment: {
    type: String,
    default: 'top'
  },

})
</script> 
<style lang="scss">
@import '@/assets/sass/core.scss';
.ui-help-tooltip {
  display: inline-block;

  &__button {
    display: flex;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    border: $base-border;
    align-items: center;
    justify-content: center;
    user-select: none;
    color: gray;
    font-size: 14px;
    font-weight: 400;
  }

  &__popper {
    word-break: break-word !important;
  }
}
</style>