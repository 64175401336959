import {wrapIntoMask} from '@/libls/phone'
import ModelInterface from '@/models/ModelInterface'
import {RawUser, UserAttrItem, UserContextItem, UserMsgrs, UserOptions} from '@/types/RawUser'
import {DateTime} from 'luxon'
import StaffUser from './StaffUser'
import {Dictionary} from '@/types/types'
import {t} from '@/i18n'
import {USER_STATUS_LEAD, USER_STATUS_REGISTERED, USER_STATUS_VERIFIED} from '@/constants'

export function USER_STATUSES_MAP(): Dictionary<{name: string}> {
  return {
    [USER_STATUS_LEAD]: {
      name: t('constants.USER_STATUS_LEAD_NAME')
    },
    [USER_STATUS_REGISTERED]: {
      name: t('constants.USER_STATUS_REGISTERED_NAME')
    },
    [USER_STATUS_VERIFIED]: {
      name: t('constants.USER_STATUS_VERIFIED_NAME')
    }
  }
}

export default class User extends ModelInterface<RawUser> {

  public getCtText (): string {
    return DateTime.fromMillis(this.data._ct).toFormat('dd.MM.yyyy HH:mm')
  }

  public getTsText (): string {
    return DateTime.fromMillis(this.data._ts).toFormat('dd.MM.yyyy HH:mm')
  }

  public setAttrs (attrs: UserAttrItem[]) {
    this.data.attrs = attrs || []
  }
  public setContext (context: UserContextItem[]) {
    this.data.context = context || []
  }

  public getAttrBySlug (slug: string): UserAttrItem | undefined {
    return this.attrs.find(attr => attr.slug === slug)
  }

  public getAttrById (id: string): UserAttrItem | undefined {
    return this.attrs.find(attr => attr.id === id)
  }

  get phone (): string {
    return this.data.phone || ''
  }

  get maskedPhone (): string {
    return wrapIntoMask(this.phone)
  }

  get status (): string {
    return this.data.status
  }
  get msgrs (): UserMsgrs {
    return this.data.msgrs
  }

  get attrs (): UserAttrItem[] {
    return this.data.attrs || []
  }

  set attrs (attrs: UserAttrItem[]) {
    this.data.attrs = attrs || []
  }

  get context (): UserContextItem[] {
    return this.data.context || []
  }

  set context (context: UserContextItem[]) {
    this.data.context = context || []
  }

  get options (): UserOptions {
    return this.data.options
  }

  set options (options: UserOptions) {
    this.data.options = options
  }

  get _profile () {
    return this.data._profile ? new StaffUser(this.data._profile) : null
  }
}