import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import {store, key} from './store'

import packageJson from '../package.json'

import UiContainer from './components/ui/UiContainer.vue'
import UiPageTitle from './components/ui/UiPageTitle.vue'
import UiBlock from './components/ui/UiBlock.vue'
import UiTabs from './components/ui/UiTabs.vue'
import UiHelpTooltip from './components/ui/UiHelpTooltip.vue'
import UiEmptyImage from './components/ui/UiEmptyImage.vue'
import UiAvatar from './components/ui/UiAvatar.vue'
import UiTable from './components/ui/UiTable.vue'
import UiPagination from './components/ui/UiPagination.vue'
import UiDatePicker from './components/ui/UiDatePicker.vue'
import UiDatePickerSeparate from './components/ui/UiDatePickerSeparate.vue'
import UiMessengerIcon from './components/ui/UiMessengerIcon.vue'
import UiMarkdown from './components/ui/UiMarkdown.vue'
import UiMaskedInput from './components/ui/UiMaskedInput.vue'
import UiIcon from './components/ui/UiIcon.vue'
import UiPopup from './components/ui/UiPopup.vue'
import UiSegmented from './components/ui/UiSegmented.vue'
import UiAnnotation from '@/components/ui/UiAnnotation.vue';

import ElementPlus from 'element-plus'
import uk from 'element-plus/es/locale/lang/uk'
import dayjs from 'dayjs'
import dayjsUkLocale from 'dayjs/locale/uk'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'element-plus/dist/index.css'
import './assets/sass/elementplus/index.scss'
import apiRequest from './services/apiRequest'

import UiDropdownItem from './components/ui/UiDropdownItem.vue'

import { useI18n } from 'vue-i18n'
import {setLang} from './services/lang'

import initI18n from '@/i18n'

import {API_METHODS, URL_PARAM_LANG_NAME} from './constants'
import {TypedBroadcastChannel} from './libls/broadcastChannel'
import {BroadcastMessageInterface, subscribe} from './broadcastListeners'

async function init () {

  window.main_channel = new TypedBroadcastChannel<BroadcastMessageInterface>('main_channel');
  subscribe()
  
  
  const url = new URL(window.location.href)
  const lang = setLang(url.searchParams.get(URL_PARAM_LANG_NAME))

  store.state.appVersion = packageJson.version

  console.log('App:', packageJson.name);
  console.log('version', packageJson.version);

  const config = await apiRequest({method: API_METHODS.config.get, data: {}})

  store.state.config = config

  dayjs.locale(dayjsUkLocale)

  const app = createApp(App, {
    setup() {
      const { t, d } = useI18n()
      return { t, d }
    }
  })

  app.component('UiContainer', UiContainer)
  app.component('UiPageTitle', UiPageTitle)
  app.component('UiBlock', UiBlock)
  app.component('UiTabs', UiTabs)
  app.component('UiHelpTooltip', UiHelpTooltip)
  app.component('UiEmptyImage', UiEmptyImage)
  app.component('UiAvatar', UiAvatar)
  app.component('UiTable', UiTable)
  app.component('UiPagination', UiPagination)
  app.component('UiDatePicker', UiDatePicker)
  app.component('UiDatePickerSeparate', UiDatePickerSeparate)
  app.component('UiMessengerIcon', UiMessengerIcon)
  app.component('UiMarkdown', UiMarkdown)
  app.component('UiMaskedInput', UiMaskedInput)
  app.component('UiDropdownItem', UiDropdownItem)
  app.component('UiIcon', UiIcon)
  app.component('UiPopup', UiPopup)
  app.component('UiSegmented', UiSegmented)
  app.component('UiAnnotation', UiAnnotation)
  for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }

  app.use(store, key)
  app.use(router)
  app.use(initI18n(lang))
  app.use(ElementPlus, {
    locale: uk
  })

  app.mount('#app')
}

init();
