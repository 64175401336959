import {USER_ATTR_INPUT_TYPE_INPUT, USER_ATTR_INPUT_TYPE_MULTISELECT, USER_ATTR_INPUT_TYPE_SELECT} from '@/constants'
import {t} from '@/i18n'
import ModelInterface from '@/models/ModelInterface'
import {UserAttrValueType} from '@/types/RawUser'
import {RawUserAttr, RawUserAttrOption, RawUserAttrTypes} from '@/types/RawUserAttr'

const ATTRS_INPUT_TYPES_MAP: {[key: string]: {emptyData: UserAttrValueType}} = {
  [USER_ATTR_INPUT_TYPE_INPUT]: {
    emptyData: ''
  },
  [USER_ATTR_INPUT_TYPE_SELECT]: {
    emptyData: ''
  },
  [USER_ATTR_INPUT_TYPE_MULTISELECT]: {
    emptyData: ''
  }
}

export function USER_ATTR_TYPES_MAP () {
  return {
    [USER_ATTR_INPUT_TYPE_INPUT]: t('constants.USER_ATTR_INPUT_TYPE_INPUT_NAME'),
    [USER_ATTR_INPUT_TYPE_SELECT]: t('constants.USER_ATTR_INPUT_TYPE_SELECT_NAME'),
    [USER_ATTR_INPUT_TYPE_MULTISELECT]: t('constants.USER_ATTR_INPUT_TYPE_MULTISELECT_NAME')
  }
}

export default class UserAttr extends ModelInterface<RawUserAttr> {

  public getStringValue (value: any): string {

    if (!value) {
      return ''
    }

    if (this.inputType === USER_ATTR_INPUT_TYPE_INPUT) {
      return value
    }

    if (this.inputType === USER_ATTR_INPUT_TYPE_SELECT) {
      if (Array.isArray(value)) {
        const stringValues = [] as string[]
        for (const item of value) {
          stringValues.push(
            this.inputOptions.find(option => option.value === item)?.label || 'error'
          )
        }
        return stringValues.join(', ')
      } else {
        return this.inputOptions.find(option => option.value === value)?.label || 'error'
      }
    }


    if (this.inputType === USER_ATTR_INPUT_TYPE_MULTISELECT) {
      const stringValues = [] as string[]
      for (const item of value) {
        stringValues.push(
          this.inputOptions.find(option => option.value === item)?.label || 'error'
        )
      }
      return stringValues.join(', ')
    }

    
      return 'error'
  }

  public getEmptyData (): UserAttrValueType {
    return ATTRS_INPUT_TYPES_MAP[this.inputType].emptyData
  }

  public isProtected (): boolean {
    return !!this.data.protected
  }

  get name (): string {
    return this.data.name
  }

  get slug (): string {
    return this.data.slug
  }

  get inputType (): RawUserAttrTypes {
    return this.data.inputType
  }

  get inputTypeName (): string {
    return USER_ATTR_TYPES_MAP()[this.data.inputType] || 'error'
  }

  get inputOptions (): RawUserAttrOption[] {
    return this.data.inputOptions || []
  }
}