import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "ui-avatar__slot"
}
const _hoisted_2 = {
  key: 1,
  class: "ui-avatar__name"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['ui-avatar', 'ui-avatar--' + $props.size, $props.square ? 'ui-avatar--square' : '']),
    style: _normalizeStyle({backgroundColor: $setup.backgroundColor})
  }, [
    ($props.src)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "ui-avatar__image",
          style: _normalizeStyle({backgroundImage: `url(${$props.src})`})
        }, null, 4 /* STYLE */))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.$slots.default)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _renderSlot(_ctx.$slots, "default")
              ]))
            : _createCommentVNode("v-if", true),
          (!_ctx.$slots.default && $props.name)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString($props.name![0].toLocaleUpperCase()), 1 /* TEXT */))
            : _createCommentVNode("v-if", true)
        ], 64 /* STABLE_FRAGMENT */))
  ], 6 /* CLASS, STYLE */))
}