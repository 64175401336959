import {Dictionary} from '@/types/types';
import {Module} from 'vuex'
import {RootState} from '../index';
import apiRequest from '@/services/apiRequest';
import {API_METHODS} from '@/constants';
import {RawBot} from '@/types/RawBot';
import {RawService} from '@/types/RawService';
import Service from '@/models/content/Service';
import ModuleModel from '@/models/content/Module';
import {RawModule} from '@/types/RawModule';

export interface ModulesStore {
  activeModules: Dictionary<ModuleModel>
}

const content = {
  namespaced: true,
  state: () => ({
    activeModules: {}
  }),
  mutations: {
    setActiveModule (state, {data, key}) {
      const item = state.activeModules[key]

      if (item) {
        item.setData(data)
      } else {
        state.activeModules[key] = new ModuleModel(data)
      }
    }
  },
  getters: {},
  actions: {
    async getActiveModules(context): Promise<Dictionary<ModuleModel>> {
      const {modules}: {modules: Dictionary<RawModule>} = await apiRequest({method: API_METHODS.modules.getBoundModules, data: {}})
      for (const key in modules) {
        const data = modules[key]
        context.commit('setActiveModule', {data, key});
      }
      return context.state.activeModules
    },
    async validateModuleOptions(context, data): Promise<Dictionary<ModuleModel>> {
      return await apiRequest({method: API_METHODS.modules.validateModuleOptions, data})
    }
  }
} as Module<ModulesStore, RootState>;

export default content;