import {USER_CONTEXT_INPUT_TYPE_DATE, USER_CONTEXT_INPUT_TYPE_DATE_TIME, USER_CONTEXT_INPUT_TYPE_INPUT, USER_CONTEXT_INPUT_TYPE_MULTISELECT, USER_CONTEXT_INPUT_TYPE_NUMBER, USER_CONTEXT_INPUT_TYPE_SELECT, USER_CONTEXT_INPUT_TYPE_TIME, USER_CONTEXT_TYPE_GLOBAL, USER_CONTEXT_TYPE_SESSION} from '@/constants'
import ModelInterface from '@/models/ModelInterface'
import {UserContextValueType} from '@/types/RawUser'
import {RawUserContext, RawUserContextOption, RawUserContextTypes, UserContextType} from '@/types/RawUserContext'
import {DateTime} from 'luxon'

import store from '@/store'
import {t} from '@/i18n'

const CONTEXT_INPUT_TYPES_MAP: {[key: string]: {emptyData: UserContextValueType}} = {
  [USER_CONTEXT_INPUT_TYPE_INPUT]: {
    emptyData: ''
  },
  [USER_CONTEXT_INPUT_TYPE_SELECT]: {
    emptyData: ''
  },
  [USER_CONTEXT_INPUT_TYPE_MULTISELECT]: {
    emptyData: ''
  },
  [USER_CONTEXT_INPUT_TYPE_DATE]: {
    emptyData: ''
  },
  [USER_CONTEXT_INPUT_TYPE_DATE_TIME]: {
    emptyData: ''
  },
  [USER_CONTEXT_INPUT_TYPE_TIME]: {
    emptyData: ''
  },
  [USER_CONTEXT_INPUT_TYPE_NUMBER]: {
    emptyData: ''
  }
}

export function USER_CONTEXT_TYPES_MAP () {
  return {
    [USER_CONTEXT_TYPE_GLOBAL]: t('constants.USER_CONTEXT_TYPE_GLOBAL_NAME'),
    [USER_CONTEXT_TYPE_SESSION]: t('constants.USER_CONTEXT_TYPE_SESSION_NAME')
  }
}

export function USER_CONTEXT_INPUT_TYPES_MAP () {
  return {
    [USER_CONTEXT_INPUT_TYPE_INPUT]: t('constants.USER_CONTEXT_INPUT_TYPE_INPUT_NAME'),
    [USER_CONTEXT_INPUT_TYPE_NUMBER]: t('constants.USER_CONTEXT_INPUT_TYPE_NUMBER_NAME'),
    [USER_CONTEXT_INPUT_TYPE_SELECT]: t('constants.USER_CONTEXT_INPUT_TYPE_SELECT_NAME'),
    [USER_CONTEXT_INPUT_TYPE_MULTISELECT]: t('constants.USER_CONTEXT_INPUT_TYPE_MULTISELECT_NAME'),
    [USER_CONTEXT_INPUT_TYPE_DATE]: t('constants.USER_CONTEXT_INPUT_TYPE_DATE_NAME'),
    [USER_CONTEXT_INPUT_TYPE_TIME]: t('constants.USER_CONTEXT_INPUT_TYPE_TIME_NAME'),
    [USER_CONTEXT_INPUT_TYPE_DATE_TIME]: t('constants.USER_CONTEXT_INPUT_TYPE_DATE_TIME_NAME')
  }
}

export default class UserContext extends ModelInterface<RawUserContext> {

  public getStringValue (value: any): string {

    if (!value) {
      return ''
    }

    if (this.inputType === USER_CONTEXT_INPUT_TYPE_INPUT) {
      return value
    }
    if (this.inputType === USER_CONTEXT_INPUT_TYPE_NUMBER) {
      return value
    }

    if (this.inputType === USER_CONTEXT_INPUT_TYPE_SELECT) {
      if (Array.isArray(value)) {
        const stringValues = [] as string[]
        for (const item of value) {
          stringValues.push(
            this.inputOptions.find(option => option.value === item)?.label || 'error'
          )
        }
        return stringValues.join(', ')
      } else {
        return this.inputOptions.find(option => option.value === value)?.label || 'error'
      }
    }


    if (this.inputType === USER_CONTEXT_INPUT_TYPE_MULTISELECT) {
      const stringValues = [] as string[]
      for (const item of value) {
        stringValues.push(
          this.inputOptions.find(option => option.value === item)?.label || 'error'
        )
      }
      return stringValues.join(', ')
    }

    if (this.inputType === USER_CONTEXT_INPUT_TYPE_DATE) {
      return DateTime.fromISO(value).toFormat('dd.MM.yyyy')
    }
  
    if (this.inputType === USER_CONTEXT_INPUT_TYPE_DATE_TIME) {
      return DateTime.fromISO(value).toFormat('dd.MM.yyyy HH:mm')
    }

    if (this.inputType === USER_CONTEXT_INPUT_TYPE_TIME) {
      return DateTime.fromISO(value).toFormat('HH:mm')
    }
    
      return 'error'
  }

  public getEmptyData (): UserContextValueType {
    return CONTEXT_INPUT_TYPES_MAP[this.inputType].emptyData
  }

  public isProtected (): boolean {
    return !!this.data.protected
  }

  public validateValue (value: any): boolean {
    
    // INPUT VALIDATION
    if (this.inputType === 'input') {
      const rules = store.state.config.validationRules

      let regexString = ''

      if (this.validation.regexType === 'email') {
        regexString = rules.email
      }
      if (this.validation.regexType === 'phone') {
        regexString = rules.phone
      }
      if (this.validation.regexType === 'regex') {
        regexString = this.validation.regex
      }
     
      if (regexString) {
        const regex = new RegExp(regexString.slice(1, regexString.lastIndexOf('/')), regexString.slice(regexString.lastIndexOf('/') + 1));
        return regex.test(value)
      }
    }

    //  INPUT VALIDATION
    if (this.inputType ===  'number') {

      const range = {
        max: this.validation.max !== null ? this.validation.max : Infinity,
        min: this.validation.min !== null ? this.validation.min : -Infinity
      }
      
      if (value < range.min || value > range.max) {
        return false
      }      
    }

    return true
  }

  get name (): string {
    return this.data.name
  }

  get slug (): string {
    return this.data.slug
  }

  get inputType (): RawUserContextTypes {
    return this.data.inputType
  }

  get contextType (): UserContextType {
    return this.data.contextType
  }

  get inputTypeName (): string {
    return USER_CONTEXT_INPUT_TYPES_MAP()[this.data.inputType] || 'error'
  }

  get contextTypeName (): string {
    return USER_CONTEXT_TYPES_MAP()[this.data.contextType] || 'error'
  }

  get inputOptions (): RawUserContextOption[] {
    return this.data.inputOptions || []
  }

  get validation () {
    return this.data.validation
  }
}