import {t} from '@/i18n'
import ModelInterface from '@/models/ModelInterface'
import {RawMailing} from '@/types/RawMailing'
import {NodeText} from '@/types/RawNode'
import {DateTime} from 'luxon'

export default class Mailing extends ModelInterface<RawMailing> {

  public getExcerpt (): string {
    let excerpt = ''
    const textItem = this.data.content.find(item => item.type === 'text') as NodeText

    if (textItem) {
      excerpt = textItem.value.default.length < 60 ?
        textItem.value.default
        : textItem.value.default.slice(0, 60) + '...'
    }
    return excerpt.replace(/(<([^>]+)>)/ig, '')
  }

  // TODOLANG
  public getDateText (): string {
    return this.date ? DateTime.fromMillis(this.date).toFormat('dd.MM.yyyy HH:mm') : t('models.content.Mailing.emptyDateText')
  }

  public getCtText (): string {
    return DateTime.fromMillis(this.data._ct).toFormat('dd.MM.yyyy HH:mm')
  }

  public getTsText (): string {
    return DateTime.fromMillis(this.data._ts).toFormat('dd.MM.yyyy HH:mm')
  }

  get date () {
    return this.data.date
  }

  get recipients () {
    return this.data.recipients
  }

  get content () {
    return this.data.content
  }

  get status () {
    return this.data.status || ''
  }

  get excerpt () {
    return this.getExcerpt()
  }

  get dateText () {
    return this.getDateText()
  }

  get ctText () {
    return this.getCtText()
  }

  get tsText () {
    return this.getCtText()
  }
}