<template>
  <div class="ui-pagination">
    <el-config-provider :locale="currentLocale">
      <div class="ui-pagination__total">
        {{ $t('components.ui.UiPagination.totalLabel') }}: {{ total }}
      </div>
      <div class="ui-pagination__cols" v-if="colsSelectList.length" ref="colsSelect">
        <el-select :modelValue="test" multiple collapse-tags @change="onActiveColsChange" size="small"
          style="width: 100%;">
          <el-option v-for="item in colsSelectList" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </div>
      <div class="ui-pagination__sizes">
        <el-select :modelValue="currentSize" @change="onSizeChange" size="small">
          <el-option v-for="size in sizes" :key="size" :label="size" :value="size" />
        </el-select>
      </div>
      <div class="ui-pagination__pagination">
        <el-pagination layout="prev, pager, next" :currentPage="currentPage" :page-size="currentSize" size="small"
          :total="total" @current-change="onPageChange" />
      </div>
    </el-config-provider>
  </div>
</template>
<script lang="ts" setup>
import {computed, nextTick, onMounted, ref} from 'vue';
import {useI18n} from 'vue-i18n';
const {t, locale} = useI18n({useScope: 'global'})
import en from 'element-plus/es/locale/lang/en'
import uk from 'element-plus/es/locale/lang/uk'

const localesMap: {[k: string]: any} = {
  en, uk
}

const currentLocale = computed(() => {
  return localesMap[locale.value]
})

const emit = defineEmits(['size-change', 'page-change', 'cols-change'])

const props = defineProps({
  total: Number,
  currentPage: Number,
  currentSize: Number,
  colsSelectList: {
    type: Array as () => {value: string, label: string}[],
    default: () => []
  },
  activeCols: {
    type: Array as () => string[],
    default: () => []
  },
  sizes: {
    type: Array as () => number[],
    default: () => [10, 50, 100]
  }
})

const colsSelect = ref<HTMLInputElement | null>(null)

function onActiveColsChange (cols: string[]) {
  emit('cols-change', cols)
}

function onPageChange (val: number) {
  emit('page-change', val)
}

function onSizeChange (val: number) {
  emit('size-change', val)
}

function setSelectPhLabel () {
  const style = document.createElement('style');
  style.innerHTML = `
  .ui-pagination__cols .el-select__wrapper:before {
    content: '${t('components.ui.UiPagination.selectColPh')}';
  }
`;

  document.head.appendChild(style);
}

const test = computed(() => {
  return props.activeCols || []
})

onMounted(() => {
  nextTick(() => {
    setSelectPhLabel()
  })
})
</script>
<style lang="scss">
.ui-pagination {
  padding: 10px 0;
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: flex-start;

  &__cols {
    width: 220px;

    .el-select__selection {
      display: none !important;
    }

    .el-select__suffix {
      margin-left: auto !important;
    }

    .el-select__wrapper {
      position: relative;

      &::before {
        position: absolute;
        left: 8px;
        color: gray;
      }
    }
  }


  &__total {
    font-weight: 700;
    font-size: 14px;
  }

  &__sizes {
    margin-left: auto;

    .el-select {
      width: 70px;
    }
  }

  &__pagination {
    .el-pager li {
      background: transparent;
    }

    .el-pagination button {
      background-color: transparent !important;
    }
  }
}
</style>