import {Dictionary} from '@/types/types';
import {Module} from 'vuex'
import {RootState} from '../index';
import apiRequest from '@/services/apiRequest';
import {API_METHODS} from '@/constants';
import {RawBot} from '@/types/RawBot';
import {RawService} from '@/types/RawService';
import Service from '@/models/content/Service';

export interface ServicesStore {
  activeServices: Dictionary<Service>
}

const content = {
  namespaced: true,
  state: () => ({
    activeServices: {}
  }),
  mutations: {
    setActiveService (state, data: RawService) {
      const bot = state.activeServices[data._id]

      if (bot) {
        bot.setData(data)
      } else {
        state.activeServices[data._id] = new Service(data)
      }
    }
  },
  getters: {
    activeServicesList: (state): Service[] => {
      const result = []
      for (const id in state.activeServices) {
        result.push(state.activeServices[id])
      }
      return result
    }
  },
  actions: {
    async getById (context, id: string): Promise<Service | null> {
      const {document}: {document: RawService | null} = await apiRequest({method: API_METHODS.services.getById, data: {id}})
      if (!document) {
        return null
      }
      return new Service(document)
    },
    async bindService (context, data): Promise<any> {
      const response = await apiRequest({method: API_METHODS.services.bindService, data})
      return response
    },
    async unbindService (context, data): Promise<Service | null> {
      const response = await apiRequest({method: API_METHODS.services.unbindService, data})
      return response
    },
    async getActiveServices (context, botId: string): Promise<Service[]> {
      const {services} = await apiRequest({method: API_METHODS.bots.get.boundServices, data: {botId}})
      if (!services) {
        return []
      }
      context.state.activeServices = {}
      for (const service of services) {
        context.commit('setActiveService', service)
      }

      const result = []
      for (const id in context.state.activeServices) {
        result.push(context.state.activeServices[id])
      }
      return result
    },
    async getShopList (context, {query = {}, size = 10000, from = 0, search}: {query: any, size: number, from: number, search: string}): Promise<any> {
      const response: {documents: RawService[]} = await apiRequest({method: API_METHODS.services.getShopList, data: {query, search, size, from, sort: {'_ct': -1}}})

      const result = []
      for (const service of response.documents) {
        result.push(new Service(service))
      }
      return {...response, documents: result}
    }
  }
} as Module<ServicesStore, RootState>;

export default content;