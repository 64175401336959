import {ModuleType} from '@/types/RawModule'
import {store} from '@/store'
import Bot from '@/models/content/Bot'

export async function checkModuleOptions (moduleId: ModuleType): Promise<boolean> {
  try {
    if (!Object.keys(store.state.modules.activeModules).length) {
      await store.dispatch('modules/getActiveModules')
    }

    const bot: Bot = store.getters['bots/getActiveBot']
    const options = bot.getBoundModule(moduleId)?.options || {}

    const {errors} = await store.dispatch('modules/validateModuleOptions', {options, module: moduleId, botId: bot._id})

    return !!!Object.keys(errors).length
  } catch (error) {
    console.error(error);
    return false
  }
}