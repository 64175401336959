<template>
  <div :class="['ui-dropdrown-item', {isClickable: clickable}]" @click="onItemClick">
    <slot></slot>
  </div>
</template>
<script lang="ts" setup>
const props = defineProps({
  clickable: {
    type: Boolean,
    default: false
  },
  command: String
})

const emits = defineEmits(['click'])

function onItemClick (e: any) {
  emits('click', props.command || e)
}
</script>
<style lang="scss">
@import '@/assets/sass/core.scss';
.ui-dropdrown-item {
  padding: 18px 22px;
  border-bottom: $border;

  &:last-child {
    border-bottom: none;
  }

  &.isClickable {
    cursor: pointer;

    &:hover {
      background: $fill-color-lighter;
    }
  }
}
</style>