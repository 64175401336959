import store from '@/store';

export type BroadMessageType = 'onModuleUpdate'

export interface BroadcastMessageInterface {
  type: BroadMessageType,
  data?: any
}

const executors: {[k in BroadMessageType]: () => any} = {
  onModuleUpdate: async () => {
    console.log('Оновити модулі');
    await store.dispatch('modules/getActiveModules')
    await store.dispatch('bots/getBot', store.state.bots.activeBotId)
  }
}


export function subscribe () {
  window.main_channel.onmessage = (event: MessageEvent<BroadcastMessageInterface>) => {
    executors[event.data.type]()
  };
}