import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ui-date-picker-separete" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_config_provider = _resolveComponent("el-config-provider")!

  return (_openBlock(), _createBlock(_component_el_config_provider, { locale: $setup.currentLocale }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_el_date_picker, {
          "disabled-date": $props.disabledDate,
          modelValue: $setup.currentValue,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ($setup.currentValue = $event)),
          onFocus: _withModifiers($setup.s, ["prevent"]),
          onBlur: _withModifiers($setup.s, ["prevent"]),
          type: "datetime",
          format: "DD.MM.YYYY HH:mm",
          "value-format": "DD.MM.YYYY HH:mm",
          "popper-class": "ui-date-picker-separete__popper",
          teleported: false
        }, null, 8 /* PROPS */, ["disabled-date", "modelValue", "onFocus", "onBlur"])
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["locale"]))
}