import { E_ACCESS, INVITE_BASE_URL} from '@/constants'
import axios from 'axios'
import auth from './auth';



const HTTP = axios.create({
  baseURL: INVITE_BASE_URL
})

export default async function ({url, method, data = {}, headers = {}}: {url?: string, method: string, data?: any, headers?: any}) {
  try {

    if (auth.readToken()) {
      headers['Authorization'] = 'Bearer ' + auth.readJwt()
    }
  
    const response = await HTTP({
      url,
      method: 'post',
      data: {
        method,
        params: data
      },
      headers
    });

    if (response.data.error) {
      throw response.data.error
    }
    
    return response.data.result;
  } catch (error) {
    throw error;
  }
}