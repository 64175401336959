// Створюємо обгортку навколо BroadcastChannel
export class TypedBroadcastChannel<BroadcastMessageInterface> {
  private _class!: BroadcastChannel

  constructor(channelName: string) {
    this._class = new BroadcastChannel(channelName)
  }

  public postMessage(message: BroadcastMessageInterface): void {
    this._class.postMessage(message);
  }

  get onmessage () {
    return this._class.onmessage
  }

  set onmessage (f) {
    this._class.onmessage = f
  }
}