import {Dictionary} from '@/types/types';
import {Module} from 'vuex'
import {RootState} from '../index';
import apiRequest from '@/services/inviteRequest';
import {API_METHODS} from '@/constants';
import {RawInvite} from '@/types/RawInvite'
import Invite from '@/models/content/Invite';

export interface InvitesStore {
  invites: Dictionary<Invite>
}

const module = {
  namespaced: true,
  state: () => ({
    invites: {},
  }),
  mutations: {
    setInvite (state, data: RawInvite) {
      const item = state.invites[data._id]
      if (item) {
        item.setData(data)
      } else {
        state.invites[data._id] = new Invite(data)
      }
    }
  },
  getters: {},
  actions: {
    async createInvites (context, data: any) {
      const response = await apiRequest({
        method: API_METHODS.invites.createInvites, 
        data
      })
      return response
    },
    async search (context, {query = {}, size = 10000, from = 0}: {query: any, size: number, from: number}) {
      const {documents, total} = await apiRequest({
        method: API_METHODS.invites.search, 
        data: {query, size, from, sort: {'_ct': -1}}
      })

      const list: Invite[] = []

      for (const user of documents) {
        list.push(new Invite(user))
      }

      return {documents: list, total}
    },
    async deleteByIds (context, {ids, token}: {ids: string[], token: string}) {
      const response = await apiRequest({
        method: API_METHODS.invites.deleteByIds, 
        data: {ids},
        headers: {'Authorization': 'Bearer ' + token}
      })
      return response;
    }
  }
} as Module<InvitesStore, RootState>;

export default module;