<template>
  <div :class="['ui-avatar', 'ui-avatar--' + size, square ? 'ui-avatar--square' : '']" :style="{backgroundColor}">
    <div v-if="src" class="ui-avatar__image" :style="{backgroundImage: `url(${src})`}"></div>
    <template v-else>
      <div class="ui-avatar__slot" v-if="$slots.default">
        <slot></slot>
      </div>
      <div v-if="!$slots.default && name" class="ui-avatar__name">
        {{ name![0].toLocaleUpperCase()}}
      </div>
    </template>
  </div>
</template>
<script lang="ts" setup>
import {computed} from 'vue';


const props = defineProps({
  name: String,
  src: String,
  size: {
    type: String,
    default: 'default'
  },
  square: {
    type: Boolean,
    default: false
  }
})

const colors = ['#FFA19B', '#F1D9AA', '#CEB9FA', '#FFB987', '#B0DE8D', '#F9B9FA', '#90EBDA', '#AAE0F1', '8AB3DA']
const letters: {[k: string]: number} = {
  a: 0,
  b: 1,
  c: 2,
  d: 3,
  e: 4,
  f: 5,
  g: 6,
  h: 7,
  i: 8,
  j: 0,
  k: 1,
  l: 2,
  m: 3,
  n: 4,
  o: 5,
  p: 6,
  q: 7,
  r: 8,
  s: 0,
  t: 1,
  u: 2,
  v: 3,
  w: 4,
  x: 5,
  y: 6,
  z: 7,
  а: 8,
  б: 0,
  в: 1,
  г: 2,
  д: 3,
  е: 4,
  ж: 5,
  з: 6,
  и: 7,
  к: 8,
  л: 0,
  м: 1,
  н: 2,
  о: 3,
  п: 4,
  р: 5,
  с: 6,
  т: 7,
  у: 8,
  ф: 0,
  х: 1,
  ц: 2,
  ч: 3,
  ш: 4,
  щ: 5,
  ю: 6,
  я: 7,
  default: 0
}

function findFirstLetter(input: string): string | undefined {
    for (let i = 0; i < input.length; i++) {
        if (/[a-zA-Zа-яА-Я]/.test(input[i])) {
            return input[i];
        }
    }
    return undefined;
}

function getColorByLetter (letter?: string) {
  const index = letters[letter || 'default'];
  return colors[index]
}

const backgroundColor = computed(() => {
  const letter = findFirstLetter(props.name || '')?.toLocaleLowerCase()
  return getColorByLetter(letter)
})
</script>
<style lang="scss">
.ui-avatar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background: #FFA19B;
  position: relative;
  overflow: hidden;

  &__image {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-position: center;
    background-size: cover;
  }

  &__slot {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  &__name {
    font-weight: 800;
    color: rgba(0, 0, 0, .5);
    user-select: none
  }

  &--default {
    width: 40px;
    height: 40px;
    font-size: 21px;
  }

  &--micro {
    width: 27px;
    height: 27px;
    font-size: 13px;
  }

  &--small {
    width: 30px;
    height: 30px;
    font-size: 14px;
  }

  &--big {
    width: 60px;
    height: 60px;
    font-size: 30px;
  }

  &--large {
    width: 150px;
    height: 150px;
    font-size: 48px;
  }

  &--square {
    border-radius: 4px;
  }
}
</style>