<template>
  <div class="ui-masked-input">
    <div class="ui-masked-input__wrapp">
      <input v-model="value" v-maska="bindedObject" :data-maska="mask" :placeholder="placeholder">
    </div>
  </div>
</template>
<script lang="ts" setup>
import {vMaska} from "maska"
import {computed, reactive} from 'vue';
const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  modelValue: {
    type: String,
    default: ''
  },
  mask: {
    type: String,
    default: '+38 (0##) ###-##-##'
  },
  placeholder: {
    type: String,
    default: ''
  },
  unmaskedValue: Boolean
})

const bindedObject = reactive({
  masked: '',
  unmasked: '',
  completed: false
})

const value = computed<string>({
  get() {
    return props.modelValue || ''
  },
  set() {
    emit('update:modelValue', props.unmaskedValue ? bindedObject.unmasked : bindedObject.masked)
  }
})
</script>
<style lang="scss">
.ui-masked-input {
  position: relative;
  font-size: 14px;
  display: inline-flex;
  width: 100%;
  line-height: 32px;
  box-sizing: border-box;
  vertical-align: middle;

  &__wrapp {
    display: inline-flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    padding: 1px 11px;
    background-color: #ffffff;
    background-image: none;
    border-radius: 4px;
    cursor: text;
    // transition: var(--el-transition-box-shadow);
    transform: translate3d(0, 0, 0);
    box-shadow: 0 0 0 1px #dcdfe6 inset;
  }


  input {
    width: 100%;
    flex-grow: 1;
    -webkit-appearance: none;
    color: #606266;
    font-size: inherit;
    height: 32px;
    line-height: 32px;
    padding: 0;
    outline: 0;
    border: none;
    background: 0 0;
    box-sizing: border-box;

    &::placeholder {
      color: #b1b3b8;
    }
  }
}
</style>
