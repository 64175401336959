import {DEFAULT_LANG, LANGS_LIST, LS_LANG_NAME} from '@/constants';

export function setLang (lang?: string | null): string {

  const value = (lang && LANGS_LIST.find(l => lang === l)) || readLang() || DEFAULT_LANG

  localStorage.setItem(LS_LANG_NAME, value)
  return readLang()
}

export function readLang () {
  const lang = localStorage.getItem(LS_LANG_NAME) as 'uk' | 'en'
  return lang
}