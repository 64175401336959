import {ModuleType} from './types/RawModule'
import {ViberSoursesSizes} from './types/RawViberBg'
import {Dictionary} from './types/types'

export const API_BASE_URL = process.env.VUE_APP_BOTS_CONTENT_BASE_URL as string
export const STAFF_BASE_URL = process.env.VUE_APP_STAFF_BASE_URL as string
export const INVITE_BASE_URL = process.env.VUE_APP_INVITE_BASE_URL as string

export const S3_BASE_URL = process.env.VUE_APP_S3_PROXY_BASE_URL as string
export const S3_TOKEN = process.env.VUE_APP_S3_PROXY_TOKEN as string
export const S3_BUCKET_NAME = '/bots-content'
export const S3_IMAGES_PATH = '/images/'
export const S3_EMAILS_PATH = '/emails/'
export const S3_VIDEO_PATH = '/video/'
export const S3_FILES_PATH = '/files/'
export const S3_VIBER_BGS_PATH = '/viber-bgs/'

export const CDN_URL = process.env.VUE_APP_CDN_URL as string

export const MODULE_NODES = 'nodes' as ModuleType
export const MODULE_MAILINGS = 'mailings' as ModuleType
export const MODULE_POLLS = 'polls' as ModuleType
export const MODULE_USERS = 'users' as ModuleType
export const MODULE_STAFF = 'staff' as ModuleType

export const MODULES_LIST = [
  MODULE_NODES,
  MODULE_MAILINGS,
  MODULE_POLLS,
  MODULE_USERS,
  MODULE_STAFF
]

/**
 * LANG
 */
export const LS_LANG_NAME = 'lang'
export const URL_PARAM_LANG_NAME = 'lang'
export const LANG_EN = 'en'
export const LANG_EN_NAME = 'english'
export const LANG_EN_SHORT_NAME = 'eng'
export const LANG_UK = 'uk'
export const LANG_UK_NAME = 'українська'
export const LANG_UK_SHORT_NAME = 'укр'

export const LANGS_LIST = [
  LANG_EN,
  LANG_UK
]

export type LANGUAGES_TYPE = 'uk' | 'en'

export const LANGS_MAP: {[k: string]: {name: string, shortName: string}} = {
  [LANG_UK]: {
    name: LANG_UK_NAME,
    shortName: LANG_UK_SHORT_NAME
  },
  [LANG_EN]: {
    name: LANG_EN_NAME,
    shortName: LANG_EN_SHORT_NAME
  }
}


export const DEFAULT_LANG = LANG_UK

export const API_METHODS = {
  config: {
    get: 'config.get'
  },
  auth: {
    login: 'auth.login'
  },
  modules: {
    getBoundModules: 'module.get.boundModules',
    validateModuleOptions: 'module.get.validateModuleOptions'
  },
  contentNode: {
    create: {
      node: 'contentNode.create.node'
    },
    get: {
      node: 'contentNode.get.node',
      children: 'contentNode.get.children',
      tree: 'contentNode.get.tree',
      list: 'contentNode.get.list',
      chain: 'contentNode.get.chain'
    },
    update: {
      node: 'contentNode.update.node',
      status: 'contentNode.update.status',
      parent: 'contentNode.update.parent'
    },
    delete: {
      node: 'contentNode.delete.node'
    }
  },
  bots: {
    create: {
      bot: 'bots.create.bot'
    },
    get: {
      bot: 'bots.get.bot',
      list: 'bots.get.list',
      boundServices: 'bots.get.boundServices'
    },
    update: {
      bot: 'bots.update.bot',
      bindMessenger: 'bots.update.bindMessenger',
      unBindMessenger: 'bots.update.unBindMessenger',
      updateService: 'bots.update.updateService',
      updateModuleOptions: 'bots.update.updateModuleOptions'
    }
  },
  users: {
    get: {
      user: 'users.get.user'
    },
    search: {
      users: 'users.search.users'
    },
    delete: {
      user: 'users.delete.user'
    },
    update: {
      user: 'users.update.user'
    }
  },
  userAttrs: {
    get: {
      userAttrs: 'userAttrs.get.userAttr',
      list: 'userAttrs.get.list',
      bySlug: 'userAttrs.get.bySlug',
    },
    create: {
      userAttr: 'userAttrs.create.userAttr'
    },
    update: {
      userAttr: 'userAttrs.update.userAttr'
    },
    delete: {
      userAttr: 'userAttrs.delete.userAttr'
    }
  },
  userContext: {
    get: {
      userContext: 'userContext.get.userAttr',
      list: 'userContext.get.list',
      bySlug: 'userContext.get.bySlug',
    },
    create: {
      userContext: 'userContext.create.userContext'
    },
    update: {
      userContext: 'userContext.update.userContext'
    },
    delete: {
      userContext: 'userContext.delete.userContext'
    }
  },
  mailings: {
    create: {
      mailing: 'mailings.create.mailing',
      sendMailing: 'mailings.create.sendMailing'
    },
    get: {
      mailing: 'mailings.get.mailing',
      list: 'mailings.get.list'
    },
    update: {
      mailing: 'mailings.update.mailing',
      planeMailing: 'mailings.update.planeMailing'
    },
    delete: {
      mailing: 'mailings.delete.mailing',
    }
  },
  polls: {
    create: {
      poll: 'polls.create.poll'
    },
    get: {
      poll: 'polls.get.poll',
      pollsList: 'polls.get.pollsList'
    },
    update: {
      poll: 'polls.update.poll',
      planePoll: 'polls.update.planePoll',
      cancelPoll: 'polls.update.cancelPoll',
      closePole: 'polls.update.closePoll'
    },
    delete: {
      poll: 'polls.delete.poll',
    }
  },
  pollResponse: {
    get: {
      list: 'pollResponse.get.list'
    }
  },
  viberBg: {
    get: {
      document: 'viberBg.get.document',
      list: 'viberBg.get.list'
    },
    create: {
      document: 'viberBg.create.document'
    },
    update: {
      document: 'viberBg.update.document'
    },
    delete: {
      document: 'viberBg.delete.document'
    }
  },
  staff: {
    getToken: 'staff.getToken',
    search: 'documents.get.search',
    app: {
      getSpecification: 'app.getSpecification',
      createSpecification: 'app.createSpecification',
      updateSpecification: 'app.updateSpecification'
    }
  },
  invites: {
    search: 'get.search',
    deleteByIds: 'delete.byIds',
    createInvites: 'create.invites'
  },
  services: {
    getById: 'services.get.service',
    getShopList: 'services.get.shopList',
    bindService: 'bots.update.bindService',
    unbindService: 'bots.update.unBindService'
  }
}

// API ERROR CODES
export const E_ACCESS = -32604
export const E_LOGIN = -326040
export const E_PARAM = -32602;

// ADMINS
export const ADMIN_PERMISSION_BOTS_CREATE = 'BOTS_CREATE'
export const ADMIN_PERMISSION_BOTS_UPDATE = 'BOTS_UPDATE'

export const ADMIN_PERMISSION_CONTENT_NODES_CREATE = 'CONTENT_NODES_CREATE'
export const ADMIN_PERMISSION_CONTENT_NODES_GET = 'CONTENT_NODES_GET'
export const ADMIN_PERMISSION_CONTENT_NODES_UPDATE = 'CONTENT_NODES_UPDATE'
export const ADMIN_PERMISSION_CONTENT_NODES_DELETE = 'CONTENT_NODES_DELETE'

export const ADMIN_PERMISSION_MAILINGS_CREATE = 'MAILINGS_CREATE'
export const ADMIN_PERMISSION_MAILINGS_GET = 'MAILINGS_GET'
export const ADMIN_PERMISSION_MAILINGS_UPDATE = 'MAILINGS_UPDATE'
export const ADMIN_PERMISSION_MAILINGS_DELETE = 'MAILINGS_DELETE'

export const ADMIN_PERMISSION_POLLS_CREATE = 'POLLS_CREATE'
export const ADMIN_PERMISSION_POLLS_GET = 'POLLS_GET'
export const ADMIN_PERMISSION_POLLS_UPDATE = 'POLLS_UPDATE'
export const ADMIN_PERMISSION_POLLS_DELETE = 'POLLS_DELETE'

export const ADMIN_PERMISSION_USERS_CREATE = 'USERS_CREATE'
export const ADMIN_PERMISSION_USERS_GET = 'USERS_GET'
export const ADMIN_PERMISSION_USERS_UPDATE = 'USERS_UPDATE'
export const ADMIN_PERMISSION_USERS_DELETE = 'USERS_DELETE'

export const ADMIN_PERMISSION_USER_ATTRS_CREATE = 'USER_ATTRS_CREATE'
export const ADMIN_PERMISSION_USER_ATTRS_GET = 'USER_ATTRS_GET'
export const ADMIN_PERMISSION_USER_ATTRS_UPDATE = 'USER_ATTRS_UPDATE'
export const ADMIN_PERMISSION_USER_ATTRS_DELETE = 'USER_ATTRS_DELETE'

export const ADMIN_PERMISSION_USER_CONTEXT_CREATE = 'USER_CONTEXT_CREATE'
export const ADMIN_PERMISSION_USER_CONTEXT_GET = 'USER_CONTEXT_GET'
export const ADMIN_PERMISSION_USER_CONTEXT_UPDATE = 'USER_CONTEXT_UPDATE'
export const ADMIN_PERMISSION_USER_CONTEXT_DELETE = 'USER_CONTEXT_DELETE'


// BOTS
export const BOT_SERVICE_STATUS_ACTIVE = 'active'
export const BOT_SERVICE_STATUS_STOPPED = 'stopped'

// NODES
export const NODE_POST_TYPE_TEXT = 'text'
export const NODE_POST_TYPE_IMAGES = 'images'
export const NODE_POST_TYPE_VIDEO = 'video'
export const NODE_POST_TYPE_ATTACHMENTS = 'attachments'
export const NODE_POST_TYPE_IMAGE = 'image'
export const NODE_POST_TYPE_CONTEXT = 'context'
export const NODE_ROOT_ID = '00000000-0000-0000-0000-000000000000'

// STATUSES
export const NODE_STATUS_ARCHIVE: 'archive' = 'archive';
export const NODE_STATUS_DRAFT: 'draft' = 'draft';
export const NODE_STATUS_IN_TEST: 'in-test' = 'in-test';
export const NODE_STATUS_PUBLISHED: 'published' = 'published';


export const NODE_STATUS_ARCHIVE_WEIGHT = -1;
export const NODE_STATUS_DRAFT_WEIGHT = 0;
export const NODE_STATUS_IN_TEST_WEIGHT = 1;
export const NODE_STATUS_PUBLISHED_WEIGHT = 2;

export const NODE_TYPE_CONTENT = 'content';
export const NODE_TYPE_SERVICE = 'service';

// VIBER SETTINGS
export const VIBER_BGS_RECOMEND_RESOLUTIONS = {
  col_1: {w: 1280, h: 210},
  col_2: {w: 640, h: 210},
  col_3: {w: 424, h: 210}
} as {[k in ViberSoursesSizes]: {w: number, h: number}}

// BOTS
export const BOT_ACTIVE_LS_KEY = 'active_bot'

export const BOT_MESSENGER_STATUS_ACTIVE = 'active'
export const BOT_MESSENGER_STATUS_PENDING = 'pending'

// USERS
export const USER_STATUS_LEAD = 'lead';
export const USER_STATUS_REGISTERED = 'registered';
export const USER_STATUS_VERIFIED = 'verified';

export const USER_LIST_ACTIVE_COLS_KEY_NAME = 'user_list_active_cols'

// MAILINGS
export const MAILING_STATUS_DRAFT = 'draft';
export const MAILING_STATUS_PLANED = 'planed';
export const MAILING_STATUS_IN_PROGRESS = 'in-progress';
export const MAILING_STATUS_SENT = 'sent';
export const MAILING_STATUS_CANCELED = 'canceled';

// ATTRIBUTES
export const USER_ATTR_INPUT_TYPE_INPUT = 'input';
export const USER_ATTR_INPUT_TYPE_SELECT = 'select';
export const USER_ATTR_INPUT_TYPE_MULTISELECT = 'multiselect'

// CONTEXT
export const USER_CONTEXT_TYPE_GLOBAL = 'global'
export const USER_CONTEXT_TYPE_SESSION = 'session'

export const USER_CONTEXT_INPUT_TYPE_INPUT = 'input';
export const USER_CONTEXT_INPUT_TYPE_NUMBER = 'number';
export const USER_CONTEXT_INPUT_TYPE_SELECT = 'select';
export const USER_CONTEXT_INPUT_TYPE_MULTISELECT = 'multiselect'
export const USER_CONTEXT_INPUT_TYPE_DATE = 'date'
export const USER_CONTEXT_INPUT_TYPE_TIME = 'time'
export const USER_CONTEXT_INPUT_TYPE_DATE_TIME = 'datetime'

// POLLS
export const POLL_STATUS_DRAFT = 'draft';
export const POLL_STATUS_PLANED = 'planed';
export const POLL_STATUS_OPENED = 'opened';
export const POLL_STATUS_COMPLETED = 'completed';
export const POLL_STATUS_CANCELED = 'canceled';

export const POLLS_STATUSES_LIST = [
  POLL_STATUS_DRAFT,
  POLL_STATUS_PLANED,
  POLL_STATUS_OPENED,
  POLL_STATUS_COMPLETED,
  POLL_STATUS_CANCELED
]

export const POLL_RESPONSE_STATUS_OPENED = 'opened'
export const POLL_RESPONSE_STATUS_EXPERED = 'expired'
export const POLL_RESPONSE_STATUS_DONE = 'done'

export const POLL_QUEST_TYPE_RADIO = 'radio'
export const POLL_QUEST_TYPE_MULTIPLE = 'mupliple'

export const INVITE_STATUS_ERROR = 'error';
export const INVITE_STATUS_GENERATED = 'generated';
export const INVITE_STATUS_SENT = 'sent';
export const INVITE_STATUS_READ = 'read';

export const POLL_RESPONSE_LIST_ACTIVE_COLS_KEY_NAME = 'poll_response_list_active_cols'

// INVITES
export const INVITE_STATUSES_LIST = [
  INVITE_STATUS_ERROR,
  INVITE_STATUS_GENERATED,
  INVITE_STATUS_SENT,
  INVITE_STATUS_READ
];


/**
 * STAFF DATA SPECS
 */
export const SPEC_TYPE_STRING = 'string';
export const SPEC_TYPE_INTEGER = 'integer';
export const SPEC_TYPE_BOOLEAN = 'boolean';
export const SPEC_TYPE_LIST_OF_STRINGS = 'list_of_strings';
export const SPEC_TYPE_EMAIL = 'email';
export const SPEC_TYPE_PHONE = 'phone';
export const SPEC_TYPE_DATE = 'date';
export const SPEC_TYPE_IMAGE = 'image';

export const SPEC_TYPES_LIST = [
  SPEC_TYPE_STRING,
  SPEC_TYPE_INTEGER,
  SPEC_TYPE_BOOLEAN,
  SPEC_TYPE_LIST_OF_STRINGS,
  SPEC_TYPE_EMAIL,
  SPEC_TYPE_PHONE,
  SPEC_TYPE_DATE,
  SPEC_TYPE_IMAGE
];

export const STAFF_LIST_ACTIVE_COLS_KEY_NAME = 'staff_list_active_cols'