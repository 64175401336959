import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ui-masked-input" }
const _hoisted_2 = { class: "ui-masked-input__wrapp" }
const _hoisted_3 = ["data-maska", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ($setup.value = $event)),
        "data-maska": $props.mask,
        placeholder: $props.placeholder
      }, null, 8 /* PROPS */, _hoisted_3), [
        [_vModelText, $setup.value],
        [$setup["vMaska"], $setup.bindedObject]
      ])
    ])
  ]))
}