<template>
  <div :class="['ui-block', {isСlosable: closable}]">
    <div class="ui-block__wrapper">
      <div v-if="label || $slots.label" :class="['ui-block__header', , {isСlosable: closable}]" @click="toggleClose">
        <span class="ui-block__label">
          <slot name="label"></slot>
          {{ label}}
        </span>
        <slot class="ui-block__slot-header" name="header"></slot>
        <div v-if="closable" :class="['ui-block__close-icon', {isClose}]">
          <el-icon><ArrowDownBold /></el-icon>
        </div>
      </div>
      <div :class="['ui-block__content', {'ui-block__content--no-padding': !label && !$slots.label}]" v-show="!closable || !isClose">
        <slot></slot>
      </div>
    </div>
    <div v-if="deleted" class="ui-block__rigthbar">
      <div class="ui-block__option" :title="$t('components.ui.UiBlock.deleteOptLabel')" @click="$emit('delete')">
        <el-icon>
          <Delete />
        </el-icon>
      </div>
      <div class="ui-block__option" :title="$t('components.ui.UiBlock.shifUpOptLabel')" @click="$emit('shiftUp')">
        <el-icon>
          <ArrowUpBold />
        </el-icon>
      </div>
      <div class="ui-block__option" :title="$t('components.ui.UiBlock.shiftDown')" @click="$emit('shiftDown')">
        <el-icon>
          <ArrowDownBold />
        </el-icon>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import {onMounted, ref} from 'vue';

const props = defineProps({
  label: String,
  deleted: Boolean,
  closable: Boolean,
  defaultClose: Boolean
})

const isClose = ref(false);

function toggleClose () {
  if (props.closable) {
    isClose.value = !isClose.value
  }
}

onMounted(() => {
  isClose.value = props.defaultClose
})
</script> 
<style lang="scss">
.ui-block {
  padding: 20px;
  background: #fff;
  display: flex;
  column-gap: 5px;
  border-radius: 6px;

  &+& {
    // margin-top: 20px;
  }

  &__wrapper {
    flex-grow: 1;
  }

  &__rigthbar {
    width: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    // gap: 7px;
  }

  &__option {
    padding: 8px;
    cursor: pointer;
    border-radius: 6px;

    &:hover {
      background: #ecf5ff;
    }
  }

  &__header {
    display: flex;
    gap: 20px;
    align-items: center;

    &.isСlosable {
      padding-right: 30px;
      position: relative;
      cursor: pointer;
    }
  }

  &__content {
    padding-top: 20px;

    &--no-padding {
      padding-top: 0;
    }
  }

  &__close-icon {
    position: absolute;
    right: 5px;
    cursor: pointer;

    &.isClose {
      transform: rotate(-90deg);
    }
  }

  &__label {
    font-size: 16px;
    font-weight: 700;
    color: #333;
  }

  &__delete {
    padding: 10px;
    cursor: pointer;
    position: absolute;
    right: 0px;
    top: -10px;

    &:hover {
      color: red;
    }
  }
}
</style>