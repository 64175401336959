<template>
  <el-config-provider :locale="currentLocale">
    <div class="ui-date-picker-separete">
      <el-date-picker :disabled-date="disabledDate" v-model="currentValue" @focus.prevent="s" @blur.prevent="s" type="datetime" format="DD.MM.YYYY HH:mm" value-format="DD.MM.YYYY HH:mm" popper-class="ui-date-picker-separete__popper" :teleported="false"/>
    </div>
</el-config-provider>
</template>
<script lang="ts" setup>
import {computed} from 'vue';
import {DateTime} from 'luxon';
import {useI18n} from 'vue-i18n';
const { locale } = useI18n({useScope: 'global'})

import en from 'element-plus/es/locale/lang/en'
import uk from 'element-plus/es/locale/lang/uk'
const localesMap: {[k: string]: any} = {
  en, uk
}

const currentLocale = computed(() => {
  return localesMap[locale.value]
})

const props = defineProps({
  value: Number,
  disabledDate: {
    type: Function,
    default: function () {return false}
  }
})
const emit = defineEmits(['change'])

const currentValue = computed({
  get () {
    return DateTime.fromMillis(props.value!).setLocale(locale.value).toFormat('dd.MM.yyyy HH:mm')
  },
  set (val) {
    emit('change', DateTime.fromFormat(val, 'dd.MM.yyyy HH:mm').toMillis())
  }
})

function s () {
  return;
}
</script>

<style lang="scss">
.ui-date-picker-separete {

  &__popper {
    top: 0 !important;
    left: 0 !important;
    position: relative !important;
    display: block !important;
    width: 322px !important;
    visibility: visible !important;
    box-shadow: none !important;
    border: none !important;
    transform: none !important;
    transition: all .01s !important;
    animation: none !important;

    .el-popper__arrow {
      display: none;
    }
    .el-picker-panel__footer {
      display: none;
    }
  }

  .el-tooltip__trigger {
    display: none;
  }
}
</style>