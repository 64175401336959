import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ui-segmented" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_segmented = _resolveComponent("el-segmented")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_segmented, {
      modelValue: $props.modelValue,
      options: $props.options,
      onChange: $setup.onChange
    }, null, 8 /* PROPS */, ["modelValue", "options"])
  ]))
}