<template>
  <div v-loading="loading" class="ui-table">
    <table class="ui-table__table">
      <thead class="ui-table__header">
        <th>№</th>
        <th v-for="col in columns">
          {{ col.label}}
        </th>
      </thead>
      <tbody class="ui-table__body" v-if="data.length">
        <template v-for="(item, i) of data">
          <tr class="ui-table__row" @click="onRowClick(item)">
            <td class="ui-table__cell" :style="{width: config.expandedRow ? '90px' : '70px'}">
              <div class="ui-table__index">
                <span>{{ i + 1}}</span>
                <el-icon :class="['ui-table__expand-icon', {isActive: isRowExpanded(i)}]" @click.stop="onExpandedIconClick(i)" :size="12"
                  v-if="config.expandedRow">
                  <ArrowRightBold />
                </el-icon>
              </div>
            </td>
            <td class="ui-table__cell" v-for="(colOptions, colName) in columns"
              :style="{width: colOptions.width ? colOptions.width + 'px' : 'auto', 'min-width': colOptions.width ? colOptions.width + 'px' : 'auto'}">
              <component v-if="colOptions.component" :is="colOptions.component" :data="item[colName]" :scope="item" :context="colOptions.context" :colName="colName" @reload="onReload">
              </component>
              <template v-else-if="!colOptions.component && !loading">
                <span v-if="item[colName]">{{ item[colName]}}</span>
                <span v-else class="ui-table__empty">{{ $t('components.ui.UiTable.emptyData') }}</span>
              </template>
            </td>
          </tr>
          <tr class="ui-table__row" v-if="isRowExpanded(i)">
            <td :colspan="Object.keys(columns).length + 1">
              <component v-if="config.expandedRowComponent" :is="config.expandedRowComponent" :row="item" @reload="onReload">
              </component>
            </td>
          </tr>
        </template>
      </tbody>
      <tfoot v-else class="ui-table__footer">
        <tr>
          <td :colspan="Object.keys(columns).length + 1">{{ $t('components.ui.UiTable.emptyData') }}</td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>
<script lang="ts" setup>
import {Dictionary} from '@/types/types'
import {computed, defineComponent, ref} from 'vue';

export interface TableCol {
  label: string,
  component?: ReturnType<typeof defineComponent>,
  context?: any
  width?: number
}

export interface TableConfig {
  cols: Dictionary<TableCol>,
  expandedRow?: Boolean,
  expandedRowComponent?: ReturnType<typeof defineComponent>
}

export interface Props {
  config: TableConfig,
  data: any[],
  loading: Boolean,
  visibleColumns?: string[]
}


const emit = defineEmits(['row-click', 'reload'])
const props = withDefaults(defineProps<Props>(), {
  visibleColumns: (props): string[] => {
    const cols: string[] = [] 
    for (const columnName in props.config.cols) {
      cols.push(columnName)
    }
    return cols
  }
})

const columns = computed((): Dictionary<TableCol> => {
  const cols: Dictionary<TableCol> = {}
  for (const colName in props.config.cols) {
    const col = props.config.cols[colName];
    if (props.visibleColumns.some(visColName => visColName === colName)) {
      cols[colName] = col
    }
  }
  return cols
})
const expadedRow = ref<number[]>([])

function onRowClick (item: any) {
  emit('row-click', item)
}

function onReload () {
  expadedRow.value = []
  emit('reload')
}

function isRowExpanded (index: number): boolean {
  return expadedRow.value.findIndex(row => row === index) >= 0
}

function onExpandedIconClick (index: number) {
  const rowIndex = expadedRow.value.findIndex(row => row === index)

  if (rowIndex >= 0) {
    expadedRow.value.splice(rowIndex, 1)
  } else {
    expadedRow.value.push(index)
  }
}

</script>
<style lang="scss">
@import '@/assets/sass/core.scss';

.ui-table {
  &__table {
    width: 100%;
  }

  &__header {
    border-bottom: $base-border;
    background: #fff;

    th {
      padding: 22px 10px;
      text-align: left;
      color: #909399;
      font-size: 14px;
      font-weight: 700;
      line-height: 1.2;
      vertical-align: middle;

      &:first-child {
        padding-left: 22px;
      }

      &:last-child {
        padding-right: 22px;
      }
    }
  }

  &__row {
    border-bottom: $base-border;
    background: #fff;

    td {
      padding: 22px 10px;
      text-align: left;
      font-size: 14px;
      vertical-align: middle;
      line-height: 1.3;

      &:first-child {
        padding-left: 22px;
      }

      &:last-child {
        padding-right: 22px;
      }
    }
  }

  &__footer {
    background: #fff;

    td {
      padding: 22px;
      text-align: center;
      color: gray;
      font-size: 14px;
    }
  }

  &__empty {
    color: gray;
    font-style: italic;
  }

  &__index {
    display: flex;
    // justify-content: space-between;
    align-items: center;
  }

  &__expand-icon {
    cursor: pointer;
    margin-left: 10px;
    transition: all .2s;

    &.isActive {
      transform: rotate(90deg);
    }
  }
}
</style>