import ModelInterface from '@/models/ModelInterface'
import {DateTime} from 'luxon'
import {RawStaffDataSpec, SpecItemTypes} from '@/types/RawStaffDataSpec'

export default class StaffDataSpec extends ModelInterface<RawStaffDataSpec> {

  public getCtText (): string {
    return DateTime.fromMillis(this.data._ct).toFormat('dd.MM.yyyy HH:mm')
  }

  public getTsText (): string {
    return DateTime.fromMillis(this.data._ts).toFormat('dd.MM.yyyy HH:mm')
  }

  public getSpecItemLabel (key: string) {
    const item = this.spec.find(it => it.key === key)
    return item?.label || 'Specification error'
  }
  public getSpecItemType (key: string): SpecItemTypes {
    const item = this.spec.find(it => it.key === key)
    return item!.type
  }

  get spec () {
    return this.data.spec
  }
}