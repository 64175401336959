<template>
  <div class="ui-tabs">
    <el-tabs :modelValue="modelValue" class="demo-tabs" @tab-click="handleClick">
      <el-tab-pane :label="tab.label" :name="tab.name" v-for="tab of tabs" :key="tab.name" />
    </el-tabs>
  </div>
</template>
<script lang="ts" setup>
import {TabsPaneContext} from 'element-plus';

export interface UiTab {
  name: string,
  label: string
}

defineProps({
  modelValue: String,
  tabs: {
    type: Array as () => UiTab[],
    default: () => []
  }
})

const emit = defineEmits(['change', 'update:modelValue'])

const handleClick = (tab: TabsPaneContext, event: Event) => {
  emit('change', tab)
  emit('update:modelValue', tab.paneName)
}
</script>
<style lang="scss">
.ui-tabs {
  .el-tabs {
    &__nav-wrap {
      padding: 0 20px;
      background: #fff;
      border-radius: 6px;
      box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.05);

      &:after {
        display: none;
      }
    }

    &__content {
      overflow: visible;
    }

    &__item {
      font-weight: 400;
      // color: $secondary-font-color;

      &.is-active {
        color: #1989FA;

        a {
          color: #1989FA;
        }
      }

      &:focus.is-active.is-focus:not(:active) {
        box-shadow: none;
      }
    }

    &__active-bar {
      height: 3px;
      background-color: #1989FA;
    }
  }
}
</style>