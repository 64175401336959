<template>
  <div class="main-container">
    <router-view />
  </div>
</template>

<script lang="ts" setup>
</script>

<style lang="scss">
  @import 'assets/sass/main.scss';
  html, body, #app, .app {
    height: 100%;
    overflow: hidden;
  }

  body {
    font-family: $main-font;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $text-color-primary;
    background-color: $bg-color-page;
  }

  .main-container {
    height: 100%;
  }
</style>
