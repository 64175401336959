import {Module} from 'vuex'
import {RootState} from '../index';
import apiRequest from '@/services/staffRequest';
import {API_METHODS} from '@/constants';
import {RawStaffDataSpec} from '@/types/RawStaffDataSpec';
import StaffDataSpec from '@/models/content/StaffDataSpec';

export interface StaffDataSpecificationStore {
  dataSpecification: StaffDataSpec | null,
}

const content = {
  namespaced: true,
  state: () => ({
    dataSpecification: null
  }),
  mutations: {
    setDataSpecification(state, data: RawStaffDataSpec) {
      const spec = state.dataSpecification
      if (spec) {
        spec.setData(data)
      } else {
        state.dataSpecification = new StaffDataSpec(data)
      }
    }
  },
  getters: {},
  actions: {
    async getSpecification (context) {
      const {document}: {document: RawStaffDataSpec | null}  = await apiRequest({method: API_METHODS.staff.app.getSpecification, data: {}})
      if (!document) {
        return null
      }
      context.commit('setDataSpecification', document)
      return context.state.dataSpecification
    }
  }
} as Module<StaffDataSpecificationStore, RootState>;

export default content;