import {LANGUAGES_TYPE} from '@/constants';
import ModelInterface from '@/models/ModelInterface'
import {RawService} from '@/types/RawService';
import {getLocale} from '@/i18n'


export default class Service extends ModelInterface<RawService> {

  public getLocaleValue (input: string): string {
    const regex = /\$\{(.+?)\}/;
    const match = input.match(regex);

    if (match) {
      const path = match[1];

      // Функція для доступу до вкладених значень за шляхом
      const getValueByPath = (obj: any, path: string) => {
        return path.split('.').reduce((acc, key) => acc && acc[key], obj);
      };

      const value = getValueByPath(this.data.locales[getLocale()], path);

      return value || '!locale error!';
    }
    return input
  }

  public getOptionSectionLabel (name: string) {
    const section = this.data.optionSections[name]
    return this.getLocaleValue(section.label)
  }

  public getOptionLabel (name: string) {
    const option = this.data.options[name]
    return this.getLocaleValue(option.inputLabel)
  }

  public getOptionPlaceholder (name: string) {
    const option = this.data.options[name]
    return this.getLocaleValue(option.inputPlaceholder)
  }

  get name () {
    return this.getLocaleValue(this.data.name)
  }

  get description () {
    return this.getLocaleValue(this.data.description)
  }

  get excerpt () {
    return this.getLocaleValue(this.data.excerpt)
  }

  get getExcerpt () {
    return this.data.excerpt
  }

  get coverUrl () {
    return this.data.coverUrl
  }

  get thumbnailUrl () {
    return this.data.thumbnailUrl
  }

  get command () {
    return this.data.command
  }

  get options () {
    return this.data.options
  }

  get optionSections () {
    return this.data.optionSections
  }

}