import {LANGUAGES_TYPE} from '@/constants';
import ModelInterface from '@/models/ModelInterface'
import {getLocale} from '@/i18n'
import {RawModule} from '@/types/RawModule';
import {cloneDeep} from 'lodash';


export default class ModuleModel extends ModelInterface<RawModule> {

  public getLocaleValue (input: string): string {
    const regex = /\$\{(.+?)\}/;
    const match = input.match(regex);

    if (match) {
      const path = match[1];

      const getValueByPath = (obj: any, path: string) => {
        return path.split('.').reduce((acc, key) => acc && acc[key], obj);
      };

      const value = getValueByPath(this.data.locales[getLocale()], path);

      return value || '!locale error!';
    }
    return input
  }

  public getDefaultOptionsValues () {
    const values: {[k: string]: any} = {}
    for (const key in this.data.options) {
      const option = this.data.options[key];
      values[key] = option.defaultValue
    }
    return cloneDeep(values)
  }

  public getOptionSectionLabel (name: string) {
    const section = this.data.optionSections[name]
    return this.getLocaleValue(section.label)
  }

  public getOptionLabel (name: string) {
    const option = this.data.options[name]
    return this.getLocaleValue(option.inputLabel)
  }

  public getOptionPlaceholder (name: string) {
    const option = this.data.options[name]
    return this.getLocaleValue(option.inputPlaceholder)
  }

  get name () {
    return this.getLocaleValue(this.data.name)
  }

  get description () {
    return this.getLocaleValue(this.data.description)
  }

  get options () {
    return this.data.options
  }

  get optionSections () {
    return this.data.optionSections
  }

}