export function normalize (number: string) {
  return number.replace(/[^0-9.]/g, '')
}

export function wrapIntoMask (number: string): string {
  let string = normalize(number)

  const countryCode = string.slice(0, 2);
  const areaCode = string.slice(2, 5);
  const firstPart = string.slice(5, 8);
  const secondPart = string.slice(8, 10);
  const thirdPart = string.slice(10);

  return `+${countryCode} (${areaCode}) ${firstPart}-${secondPart}-${thirdPart}`;
}