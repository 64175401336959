import { InjectionKey } from 'vue'
import { createStore, useStore as baseUseStore, Store } from 'vuex'
import {API_METHODS} from '@/constants';
import apiRequest from '@/services/apiRequest';
import auth from '../services/auth';
import content, {ContentStore} from './modules/content'
import bots, {BotsStore} from './modules/bots'
import users, {UsersStore} from './modules/users'
import userAttrs, {UserAttrsStore} from './modules/userAttrs'
import userContext, {UserContextStore} from './modules/userContext'
import mailings, {MailingsStore} from './modules/mailings'
import Admin from '@/models/content/Admin';
import {RawAdmin} from '@/types/RawAdmin';
import polls, {PollsStore} from './modules/polls';
import pollResponse, {PollResponseStore} from './modules/pollResponse';
import viberBg, {ViberBgState} from './modules/viberBg';
import staffUsers, {StaffUsersStore} from './modules/staffUser';
import invites, {InvitesStore} from './modules/invites';
import staffDataSpecification, {StaffDataSpecificationStore} from './modules/staffDataSpecification';
import services, {ServicesStore} from './modules/services';
import modules, {ModulesStore} from './modules/modules';

export interface RootState {
  appVersion: string,
  isLoading: boolean
  admin: Admin | null
  config: {
    validationRules: {
      email: string,
      phone: string
    }
  }
  activeCompany: string
  content: ContentStore,
  bots: BotsStore
  users: UsersStore,
  userAttrs: UserAttrsStore,
  userContext: UserContextStore,
  mailings: MailingsStore,
  polls: PollsStore,
  pollResponse: PollResponseStore,
  viberBg: ViberBgState,
  staffUsers: StaffUsersStore,
  invites: InvitesStore,
  staffDataSpecification: StaffDataSpecificationStore,
  services: ServicesStore,
  modules: ModulesStore
}

export const key: InjectionKey<Store<RootState>> = Symbol()

export const store = createStore<RootState>({
  // @ts-ignore
  state: {
    isLoading: false,
    admin: null,
    appVersion: '',
    activeCompany: '',
    // @ts-ignore
    config: null
  },
  mutations: {
    toggleLoading (state, val) {
      state.isLoading = !!val
    },
    setAdmin (state, data: RawAdmin | null) {
      if (data) {
        state.admin = new Admin(data)
      } else {
        state.admin = null
      }
    },
    setActiveCompany (state, id: string) {
      state.activeCompany = id
    }
  },
  getters: {
    isAuthenticated: (state):boolean => {
      return !!state.admin
    }
  },
  actions: {
    clearGlobalData () {
      store.state.userAttrs.userAttrsByIdMap = {}
      store.state.userAttrs.userAttrsBySlugMap = {}
      store.state.userContext.userContextByIdMap = {}
      store.state.userContext.userContextBySlugMap = {}
      store.state.bots.bots = {}
      store.state.content.expandedNodes = []
      store.state.content.nodes = {}
      store.state.mailings.mailings = {}
      store.state.users.users = {}
      store.state.services.activeServices = {}
      store.state.staffDataSpecification.dataSpecification = null
      store.state.modules.activeModules = {}
    },
    resetGlobalData () {
      store.state.userAttrs.userAttrsByIdMap = {}
      store.state.userAttrs.userAttrsBySlugMap = {}
      store.state.userContext.userContextByIdMap = {}
      store.state.userContext.userContextBySlugMap = {}
      store.state.content.expandedNodes = []
      store.state.content.nodes = {}
      store.state.mailings.mailings = {}
      store.state.users.users = {}
      store.state.services.activeServices = {}
      store.state.staffDataSpecification.dataSpecification = null
      store.state.modules.activeModules = {}
    }
  },
  modules: {
    content,
    bots,
    users,
    userAttrs,
    userContext,
    mailings,
    polls,
    pollResponse,
    viberBg,
    staffUsers,
    invites,
    staffDataSpecification,
    services,
    modules
  }
})

export function useStore () {
  return baseUseStore(key)
}

export default store