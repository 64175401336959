<template>
  <el-config-provider :locale="currentLocale">
  <div class="ui-date-picker">
    <el-date-picker v-model="currentValue" type="datetime" :placeholder="$t('components.ui.UiDatePicker.pickerPh')" format="DD.MM.YYYY HH:mm"
          value-format="DD.MM.YYYY HH:mm" :clearable="clearable"/>
  </div>
</el-config-provider>
</template>
<script lang="ts" setup>
import {computed} from 'vue';
import { DateTime } from 'luxon';
import en from 'element-plus/es/locale/lang/en'
import uk from 'element-plus/es/locale/lang/uk'
import {useI18n} from 'vue-i18n';
const { locale } = useI18n({useScope: 'global'})

const localesMap: {[k: string]: any} = {
  en, uk
}

const currentLocale = computed(() => {
  return localesMap[locale.value]
})

const props = defineProps({
  value: Number,
  clearable: Boolean
})
const emit = defineEmits(['change'])

const currentValue = computed({
  get () {
    return DateTime.fromMillis(props.value!).setLocale(locale.value).toFormat('dd.MM.yyyy HH:mm')
  }, 
  set (val) {
    emit('change', DateTime.fromFormat(val, 'dd.MM.yyyy HH:mm').toMillis())
  }
})

</script>