<template>
  <div class="ui-icon" :style="{height: size + 'px'}" v-html="iconHtml"></div>
</template>
<script lang="ts" setup>
import {computed} from 'vue';


const MAP: {[k: string]: string} = {
  singout: `
  <svg viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.4444 11.5L17 8M17 8L13.4444 4.5M17 8H4.55556M9.88889 11.5V12.375C9.88889 13.8248 8.69502 15 7.22222 15H3.66667C2.19391 15 1 13.8248 1 12.375V3.625C1 2.17526 2.19391 1 3.66667 1H7.22222C8.69502 1 9.88889 2.17526 9.88889 3.625V4.5" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  `,
  plus: `
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 1V15" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
    <path d="M1 8H15" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
  </svg>
  `
}

const props = defineProps({
  icon: {
    type: String,
    required: true
  },
  size: {
    type: Number,
    default: 18
  }
})

const iconHtml = computed(() => {
  return MAP[props.icon]
})

</script>
<style lang="scss">
.ui-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 100%;
  }
}

</style>