<template>
  <div :class="['ui-popup', {hasCloseIcon: showCloseIcon}]" v-if="isVisible" @click="closeModal">
    <div class="ui-popup__modal" @click.stop>
      <div class="ui-popup__close-icon" v-if="showCloseIcon" @click="closeModal">
        <el-icon :size="20">
          <Close />
        </el-icon>
      </div>
      <PerfectScrollbar :options="{suppressScrollX: true, swipeEasing: true, scrollYMarginOffset: 5}">
        <div class="ui-popup__content">
          <slot></slot>
        </div>
      </PerfectScrollbar>
    </div>
  </div>
</template>
<script lang="ts" setup>
import {ref} from 'vue';
import {PerfectScrollbar} from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/style.css';

const props = defineProps({
  showCloseIcon: {
    type: Boolean,
    default: true
  }
})

const emit = defineEmits(['close', 'open'])

const isVisible = ref(false)

function openModal () {
  isVisible.value = true
  emit('open')
}

function closeModal () {
  isVisible.value = false
  emit('close')
}

defineExpose({
  openModal,
  closeModal
})
</script>
<style lang="scss">
@import '@/assets/sass/core.scss';

.ui-popup {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $overlay-color-lighter;
  padding: 60px 45px;

  &__modal {
    display: flex;
    flex-direction: column;
    position: relative;
    min-width: 400px;
    max-height: 100%;
    background: #fff;
    border-radius: 8px;
    padding: 15px 2px 15px 20px;
  }

  &__content {
    max-height: 100%;
    padding: 5px 18px 5px 0;
  }

  &__close-icon {
    position: absolute !important;
    z-index: 1;
    right: 2px;
    top: 2px;
    padding: 10px 10px 5px 5px;
    background: #fff;
    cursor: pointer;
    border-top-right-radius: 10px
  }
}
</style>