import {Dictionary} from '@/types/types';
import {Module} from 'vuex'
import {RootState} from '../index';
import apiRequest from '@/services/staffRequest';
import {API_METHODS} from '@/constants';
import {RawStaffUser} from '@/types/RawStaffUser';
import StaffUser from '@/models/content/StaffUser';

export interface StaffUsersStore {
  staffUsers: Dictionary<StaffUser>
}

const module = {
  namespaced: true,
  state: () => ({
    staffUsers: {},
  }),
  mutations: {
    setUser (state, userData: RawStaffUser) {
      const user = state.staffUsers[userData._id]
      if (user) {
        user.setData(userData)
      } else {
        state.staffUsers[userData._id] = new StaffUser(userData)
      }
    }
  },
  getters: {},
  actions: {
    async search (context, {query = {}, size = 10000, from = 0, token}: {query: any, size: number, from: number, token: string}) {
      const {documents, total} = await apiRequest({
        method: API_METHODS.staff.search, 
        data: {query, size, from},
        headers: {'Authorization': 'Bearer ' + token}
      })

      const list: StaffUser[] = []

      for (const user of documents) {
        list.push(new StaffUser(user))
      }

      return {documents: list, total}
    }
  }
} as Module<StaffUsersStore, RootState>;

export default module;