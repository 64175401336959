import {Dictionary} from '@/types/types';
import {Module} from 'vuex'
import {RootState} from '../index';
import apiRequest from '@/services/apiRequest';
import {API_METHODS} from '@/constants';
import {RawUserContext} from '@/types/RawUserContext';
import UserContext from '@/models/content/UserContext'

export interface UserContextStore {
  userContextByIdMap: Dictionary<UserContext>,
  userContextBySlugMap: Dictionary<UserContext>,
}

const module = {
  namespaced: true,
  state: () => ({
    userContextByIdMap: {},
    userContextBySlugMap: {}
  }),
  mutations: {
    setUserContext(state, data: RawUserContext) {
      const userContext = state.userContextByIdMap[data._id]
      if (userContext) {
        userContext.setData(data)
      } else {
        const model = new UserContext(data)
        state.userContextByIdMap[data._id] = model
        state.userContextBySlugMap[data.slug] = model
      }
    }
  },
  getters: {},
  actions: {
    async getUserContext (context, id: string) {
      const {userContext} = await apiRequest({method: API_METHODS.userContext.get.userContext, data: {id}})
      if (!userContext) {
        return null
      }
      context.commit('setUserContext', userContext)
      return context.state.userContextByIdMap[userContext._id]
    },
    async getUserContextBySlug (context, data) {
      const {userContext} = await apiRequest({method: API_METHODS.userContext.get.bySlug, data})
      if (!userContext) {
        return null
      }
      context.commit('setUserContext', userContext)
      return context.state.userContextByIdMap[userContext._id]
    },
    async getList (context, {query = {}}): Promise<{list: UserContext[], mapById: Dictionary<UserContext>, mapBySlug: Dictionary<UserContext> }> {
      const {userContexts}: {userContexts: RawUserContext[]} = await apiRequest({method: API_METHODS.userContext.get.list, data: {query}})
      if (!userContexts) {
        return {list: [], mapById: context.state.userContextByIdMap, mapBySlug: context.state.userContextBySlugMap}
      }

      const list = []
      for (const item of userContexts) {
        context.commit('setUserContext', item)
        list.push(context.state.userContextByIdMap[item._id])
      }
      return {list, mapById: context.state.userContextByIdMap, mapBySlug: context.state.userContextBySlugMap}
    },
    async createUserContext (_, data) {
      return await apiRequest({method: API_METHODS.userContext.create.userContext, data: {userContext: data}})
    },
    async updateUserContext (_, {id, data}) {
      return await apiRequest({method: API_METHODS.userContext.update.userContext, data: {id, fields: data}})
    },
    async deleteUserContext (_, id) {
      return await apiRequest({method: API_METHODS.userContext.delete.userContext, data: {id}})
    },
  }
} as Module<UserContextStore, RootState>;

export default module;