import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "ui-icon",
    style: _normalizeStyle({height: $props.size + 'px'}),
    innerHTML: $setup.iconHtml
  }, null, 12 /* STYLE, PROPS */, _hoisted_1))
}